import { ALL_ROLES_FETCHED } from "../actions/actionTypes";

const allRolesReducer = (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case ALL_ROLES_FETCHED:
      return payload;

    default:
      return state;
  }
};

export default allRolesReducer;

const en = {
  role: "Role",
  roles: "Roles",
  user: "User",
  users: "Users",
  usersWithCount: "{{count}} Role User",
  usersWithCount_plural: "{{count}} Role Users",
  web: "Web",
  mobile: "Mobile",
  language: "Language",
  currency: "Currency",
  supervisor: "Supervisor",
  worker: "Worker",
  search: "Search Roles",
  actions: "Actions",
  module: "Module",
  shifts: "Shifts",
  shift: "Shift",
  amount: "Amount",
  from: "From",
  to: "To",
  price: "Price",
  KM: "KM",
  logo: "Logo",
  color: "Color",
  url: "URL",
  secret: "Secret",
  events: "Events",

  "system settings": "System Settings",
  "general settings": "General Settings",
  "default wide settings": "Default wide settings",
  "order settings": "Order Settings",
  "delivery prices for orders": "Delivery/Return prices for orders",
  "shift settings": "Shift Settings",
  "working hours for captains": "Working hours for captains",
  "custody settings": "Custody Settings",
  "captains custody settings": "Captains custody settings",
  "webhooks settings": "Webhooks Settings",
  "sync with other system": "Sync with other system",
  "max allowed wallet": "Maximum money amount in wallet",
  "leave it empty for no max": "Leave it empty for no amount limit",
  "assigned captains": "Assigned Captains",
  "working hours": "Working Hours",
  "off days": "Off Days",
  "add shift title": "Add New Shift",
  "edit shift title": "Edit Shift",
  "clone shift title": "Clone New Shift",
  "shift name": "Shift Name",
  "start time": "Start Time",
  "end time": "End Time",
  "day off": "Day Off",
  "delivery/return distance prices": "Delivery/Return distance prices",
  "apply trip fees on": "Apply trip fees on",
  "delivery orders": "Delivery orders",
  "return orders": "Return orders",
  "company logo": "Company Logo",
  "order max queue duration": "Order Max Queue Duration",
  "order will be cancelled": "Order will be cancelled after this duration",

  "roles and permissions": "Roles and Permissions",
  "role name": "Role Name",
  "add role": "Add New Role",
  "edit role": "Edit Role",
  "clone role": "Clone New Role",
  "select all": "Select All",
  "role details": "Role Details",
  "role name ar": "Role Name Arabic",
  "role name en": "Role Name English",
  "system level role": "System Level Role",
  "mobile permissions": "Mobile Permissions",
  "web permissions": "Web Permissions",
  "role users": "Role Users",
  "role permissions": "Role Permissions",

  "no access": "No Access",
  "no roles": "There's no roles yet",
  "no role users": "Thers's no role users yet",
  "no users without role": "There's no users without the role",
  "no shifts": "There's no shifts yet",

  EGP: "Egyptian Pound",
  USD: "United States Dollar",
  SAR: "Saudi Arabian Riyal",
  EGP_short: "EGP",
  SAR_short: "SAR",

  sun: "Sunday",
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",

  add: "Add Role",
  edit: "Edit Role",
  clone: "Clone Role",
  save: "Save",
  "save & add": "Save & Add",
  "b cancel": "Cancel",
  "add user": "Add User",
  "add shift": "Add Shift",
  "edit shift": "Edit Shift",
  "clone shift": "Clone Shift",

  "change user role": "Change User Role",
  "add user to": "Add User to",
  "add role to user": "Add Role to User",

  "required field": "This field is required",
  "too short name": "Too short name, should be 2 letters at least",
  "too long name": "Too long name, should be 50 letters at most",
  "invalid role": "Invalid Role",
  "invalid amount": "Invalid amount",
  "invalid time": "Invalid time",
  "start should be before end": "Start should be before end",
  "end should be after start": "End should be after start",
  "fill one day": "One day should be filled at least",
  "should equal last to": "Should equal last end",
  "should be greater than from": "Should be greater than start",
  "should be greater than last price": "Should be greater than last price",
  "59 max minutes": "59 minutes at most",
  "30 min minutes": "30 minutes at least",
  "2 max hours": "2 hours at most",
  "invalid url": "Invalid URL",
  "20 characters at least": "Should be 20 characters at least",
  "100 characters at most": "Should be 100 characters at most",

  dashboard_label: "Dashboard",
  orders_label: "Orders",
  branches_label: "Branches",
  team_label: "Team",
  warehouse_label: "Warehouse",
  tools_label: "Tools",
  shelves_label: "Shelves",
  wallets_label: "Wallets",
  reports_label: "Reports",
  settings_label: "Settings",
  system_label: "System",
  permissions_label: "Permissions",
  "mobile access": "Mobile Access",
  dashboard: {
    system_overview: "System Overview",
    orders_overview: "Orders Overview",
    tools_custody: "Tools Custody",
    captains_queue: "Captains Queue",
    top_captains: "Top performing captains",
    top_branches: "Top performing branches",
  },
  orders: {
    view: "View orders",
    statistics: "Orders statistics",
    map: "Realtime orders map",
    timeline: "Realtime orders timeline",
    receive_orders: "Receive orders [Must be Captain]",
  },
  branches: {
    view: "View branches",
    add: "Add new branches",
    edit: "Edit branches",
    move_users: "Move users between branches",
  },
  team: {
    view: "View users",
    add: "Add new users",
    edit: "Edit users",
  },
  warehouse: {
    sections: {
      tools: {
        view: "View tools",
        add: "Add new tools",
        edit: "Edit tools",
        move_tool: "Move tools between users",
      },
      shelves: {
        view: "View shelves",
        add: "Add new shelves",
        edit: "Edit shelves",
        download_codes: "Download shelves barcodes",
      },
    },
  },
  wallets: {
    view: "View wallet custody",
    add: "Add custody to users",
    collect: "Collect custody from users",
  },
  reports: {
    view: "View reports",
    add: "Add new reports",
  },
  settings: {
    sections: {
      system: {
        edit_general_settings: "Edit general settings",
        edit_orders_settings: "Edit orders settings",
        edit_custody_settings: "Edit wallet custody settings",
        edit_webhooks_settings: "Edit webhooks settings",

        view_shifts: "View shifts",
        add_shifts: "Add new shifts",
        edit_shifts: "Edit shifts",
      },
      permissions: {
        view: "View roles and permissions",
        add: "Add new roles",
        edit: "Edit roles",
        move_users: "Move users between roles",
      },
    },
  },
};

export default en;

const ar = {
  heading:
    "ألترا فاست لإدارة أعمال التوصيل، اختر كيف تريد إنشاء أعمالك السريعة.",
  subheading: "حان وقت تطوير أعمالك. توسّع، وفّر واكسب.",
  store: "متجر",
  restaurant: "مطعم",
  phone: "الهاتف",
  currency: "العملة",
  email: "البريد الإلكتروني",
  password: "كلمة المرور",

  adv_1: "وفّر ٤٨٪ من تكاليف التوصيل،",
  adv_2: "قلل الأوقات المستهلكة للتوصيل إلى ٨٣٪،",
  adv_3: "احصل على تقارير أسرع ١٠ مرات عن قبل.",

  "have questions": "هل لديك أسئلة؟",
  "call us": "تواصل معنا على",
  "or email us": "أو ارسل بريد إلكتروني على",
  "start your 30 days": "ابدأ ٣٠ يوم",
  "free trial": "تجربة مجانية.",
  "company info": "معلومات الشركة",
  "owner info": "معلومات المالك",
  "company name": "إسم الشركة",
  "owner name": "إسم المالك",
  "cloud kitchen": "مطبخ سريع",
  "dark store": "متجر سريع",
  "verify email": "تأكيد البريد الإلكتروني",
  "email sent": "لقد أرسلنا إليك رسالة تأكيد على",
  "email resent": "تم إعادة إرسال رسالة التأكيد على",
  "verification code": "كود التأكيد",
  "enter email and code": "أدخل البريد الإلكتروني وكود التحقق لتفعيل حسابك.",
  "already registered": "هل قمت بالتسجيل سابقًا؟ للتفعيل",
  "have an account": "هل لديك حساب؟ تسجيل الدخول",
  "didnt register": "لم تسجل؟ إشترك مجانًا",

  verify: "تأكيد",
  resend: "إعادة إرسال",
  "start trial": "ابدأ ٣٠ يوم تجربة مجانية",
  "resend after": "إعادة إرسال بعد",

  "required field": "هذا الحقل أساسي",
  "too short name": "الإسم قصير جدًا، يجب أن يكون ٥ حروف على الأقل",
  "too long name": "الإسم طويل جدًا، يجب أن يكون ٢٥ حرف على الأقل",
  "invalid phone": "رقم التليفون غير صحيح",
  "invalid email": "البريد الإلكتروني غير صحيح",
  "too short password": "يجب أن تكون كلمة المرور ٦ حروف على الأقل",
  "invalid code": "يجب أن يكون الكود ٦ أرقام",
};

export default ar;

import {
  ALL_ROLE_USERS_FETCHED,
  USER_ROLE_UPDATED,
} from "../actions/actionTypes";

const allRoleUsersReducer = (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case ALL_ROLE_USERS_FETCHED:
      return payload;

    case USER_ROLE_UPDATED:
      const { data, goingOut } = payload;
      const { id, name, username, image } = data;

      // if user is going out of displayed role
      return goingOut
        ? // remove user
          state.filter((user) => user.id !== id)
        : // add user
          [...state, { id, name, username, image }];

    default:
      return state;
  }
};

export default allRoleUsersReducer;

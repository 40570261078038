const ar = {
  username: "إسم المستخدم",
  email: "البريد الإلكتروني",
  login: "تسجيل الدخول",
  password: "كلمة المرور",
  code: "كود التحقيق",
  verify: "تأكيد",
  save: "حفظ",
  or: "أو",
  signup: "الإشتراك بتجربة مجانية",
  "keep me logged in": "إبقني مسجل",
  "new password": "كلمة المرور الجديدة",
  "change password": "إستبدال كلمة المرور",
  "confirm password": "تأكيد كلمة المرور",
  "forgot password": "هل نسيت كلمة المرور؟",
  "password recovery": "إستعادة كلمة المرور",
  "we will send a verification code": "سنقوم بإرسال كود التحقيق لك",
  "please enter received code": "برجاء إدخال كود التحقيق الذي حصلت عليه",
  "request code": "اطلب الكود",
  "having code already?": "تمتلك كود التحقيق بالفعل؟",
  "verify code": "تأكيد كود التحقيق",
  "don't have code?": "لا تمتلك كود التحقيق؟",
  "two passwords not matching": "كلمتا المرور الجديدتان غير متطابقتان",
  "required field": "هذا الحقل أساسي",
  "required six digits": "يجب أن يكون الكود 6 أرقام",
  "required six characters": "يجب أن تكون كلمة المرور 6 حروف على الأقل",
  "invalid username": "إسم المستخدم غير صحيح",
  "invalid username or password":
    "إسم المستخدم أو كلمة المرور خاطئة، برجاء التأكد والمحاولة لاحقًا",
  "invalid username or email":
    "إسم المستخدم أو البريد الإلكتروني خاطئ، برجاء التأكد والمحاولة لاحقًا",
  "password hint":
    "لكلمة مرور قوية إستخدم: \n- الأرقام \n- الحروف الصغيرة والكبيرة والمميزة",
  "password changed":
    "تم تغيير كلمة المرور بنجاح، برجاء تسجيل الدخول بكلمة المرور الجديدة",
  "invalid verification code":
    "كود التحقيق الذي أدخلته غير صحيح أو منتهي الصلاحية، برجاء التأكد و المحاولة لاحقًا",
};

export default ar;

import { CAPTAINS_PAGE_FETCHED, CAPTAIN_MOVED } from "../actions/actionTypes";

const INIT_STATE = { metadata: {}, data: [], filters: {} };

const captainsPageReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CAPTAINS_PAGE_FETCHED:
      return payload;

    case CAPTAIN_MOVED:
      return {
        ...state,
        data: state.data.filter((c) => c.id !== payload.id),
        metadata: { ...state.metadata, results: state.metadata.results - 1 },
      };

    default:
      return state;
  }
};

export default captainsPageReducer;

import { ORDER_FETCHED } from "../actions/actionTypes";

const singleOrderReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case ORDER_FETCHED:
      return payload;

    default:
      return state;
  }
};

export default singleOrderReducer;

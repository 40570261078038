import { StrictMode } from "react";
import ReactDOM from "react-dom";

import initSentry from "./utils/initSentry";

import { Provider } from "react-redux";
import store from "./redux/store";

import "./i18n";

import App from "./modules/App";

initSentry();

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);

import { TOP_CAPTAINS_FETCHED } from "../actions/actionTypes";

const INIT_STATE = { metadata: {}, data: [], filters: {} };

const topCaptainsReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOP_CAPTAINS_FETCHED:
      return payload;

    default:
      return state;
  }
};

export default topCaptainsReducer;

const en = {
  dashboard: "Dashboard",
  from: "From",
  to: "To",
  captain: "Captain",
  branch: "Branch",
  ranking: "Ranking",
  orders: "Orders",
  distance: "Distance",
  income: "Income",
  wallets: "Wallets",
  wallet: "Wallet",
  status: "Status",
  tool: "Tool",
  EGP: "EGP",
  SAR: "SAR",
  KM: "KM",
  km_amount: "{{amount}}KM",

  "system overview": "System Overview",
  "captains queue": "Captains Queue",
  "delivery orders": "Delivery Orders",
  "return orders": "Return Orders",
  "cancelled orders": "Cancelled Orders",
  "rejected orders": "Rejected Orders",
  "last updated at": "Last updated at",
  "top performing captains": "Top Performing Captains",
  "top performing branches": "Top Performing Branches",
  "change dates for new data": "Change dates for new data",
  "last activity": "Last Activity",
  "orders statistics": "Orders Statistics",
  "for 8 days": "For 8 days",
  "tools custody": "Tools Custody",

  "nothing to show": "Nothing to show right now",
  "no branches": "No branches found",

  "invalid date": "Invalid date",
  "should be before end": "Should be before end",
  "should be after start": "Should be after start",

  update: "Update",

  ACTIVE: "Active",
  IDLE: "Idle",
  OFF_SHIFT: "Off shift",
  VACATION: "Vacation",
  BREAK: "Break",
  MAX_WALLET: "Wallet Max",
};

export default en;

import { REPORTS_PAGE_FETCHED } from "../actions/actionTypes";

const INIT_STATE = { metadata: {}, data: [], filters: {} };

const reportsPageReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case REPORTS_PAGE_FETCHED:
      return payload;

    default:
      return state;
  }
};

export default reportsPageReducer;

const ar = {
  reports: "التقارير",
  report: "التقرير",
  customers: "العملاء",
  workers: "العمّال",
  products: "المنتجات",
  visits: "الزيارات",
  save: "حفظ",
  send: "إرسال",
  cancel: "رجوع",
  creator: "المُنشِئ",
  from: "من",
  to: "إلى",
  actions: "الخيارات",
  status: "الحالة",
  type: "النوع",
  add: "إضافة",
  "created by": "{{name}} أُنشئ بواسطة",
  "created on": "أُنشئ بتاريخ",
  "add report": "أضِف تقرير",
  "from date": "من تاريخ",
  "to date": "إلى تاريخ",
  "create new report": "إضافة تقرير جديد",
  "send report by email": "إرسال التقرير بالبريد",
  "download report": "تحميل التقرير",
  "email number": "{{num}} البريد",
  "report name ar": "إسم التقرير بالعربية",
  "report name en": "إسم التقرير بالإنجليزية",
  "report details": "تفاصيل التقرير",
  "report options": "خيارات التقرير",
  "visits options": "خيارات الزيارات",
  "products options": "خيارات المنتجات",
  "no reports": "لا يوجد تقارير حتى الآن",
  "no customers": "لا يوجد عملاء حتى الآن",
  "no users": "لا يوجد مستخدمون حتى الآن",
  "no products": "لا يوجد منتجات حتى الآن",
  "required field": "هذا الحقل أساسي",
  "too short name": "الإسم قصير جدًا، يجب أن يكون حرفين على الأقل",
  "too long name": "الإسم طويل جدًا، يجب أن يكون 50 حرف على الأكثر",
  "invalid date": "هذا التاريخ غير صحيح",
  "date should not be in future": "يجب ألّا يكون التاريخ في المستقبل",
  "start date after end date": "تاريخ البدء بعد تاريخ الإنتهاء",
  "end date before start date": "تاريخ الإنتهاء قبل تاريخ البدء",
  "invalid customer": "هذا العميل غير صحيح",
  "invalid worker": "هذا العامل غير صحيح",
  "invalid product": "هذا المنتج غير صحيح",
  "invalid user": "هذا المستخدم غير صحيح",
  "invalid email": "البريد الإلكتروني غير صحيح",
  "repeated email": "عنوان هذا البريد مكرر",

  PRODUCTS: "منتجات",
  VISITS: "زيارات",

  MISSED_VISITS: "زيارات فائتة",
  FINISHED_VISITS: "زيارات منتهية",
  CANCELLED_VISITS: "زيارات ملغاة",
  DELAYED_VISITS: "زيارات متأخرة",

  PRODUCTS_CUSTODY: "عهدة المنتجات",
  PRODUCTS_STORE: "مخزون المنتجات",

  NEW: "جديد",
  "IN-PROGRESS": "قيد التنفيذ",
  FINISHED: "إنتهى",
  ERROR: "خطأ",
};

export default ar;

import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";

import NotFound from "../../../../base/views/NotFound";
import AccessDenied from "../../../../base/views/AccessDenied";

import Notification from "../Notification";
import ProgressBar from "../ProgressBar";
import TrialPanel from "../TrialPanel";

import TopBar from "./TopBar";
import SideMenu from "./SideMenu";

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  drawer: {
    width: drawerWidth,
    backgroundColor: "transparent",
    flexShrink: 0,
    whiteSpace: "nowrap",
    border: "none",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      backgroundColor: theme.palette.grey[300],
      width: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[500],
      borderRadius: 4,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
  drawerClose: {
    width: theme.spacing(9),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("xs")]: {
      width: 0,
      backgroundColor: theme.palette.background.paper,
    },
  },
  drawerHidden: {
    width: 0,
  },
  appBar: {
    width: "100%",
    boxShadow: "0px 2px 4px #ADB5D15C",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  content: {
    flexGrow: 1,
    alignSelf: "stretch",
    marginLeft: theme.spacing(9),
    width: `calc(100% - ${theme.spacing(9)}px)`,
    padding: theme.spacing(3, 3, 3, 1),
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("xs")]: {
      width: 1024,
      marginLeft: 0,
      marginTop: 56,
      padding: theme.spacing(3),
    },
  },
  contentShift: {
    flexGrow: 1,
    alignSelf: "stretch",
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    padding: theme.spacing(3),
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.spacing(9)}px)`,
      marginLeft: theme.spacing(9),
    },
    [theme.breakpoints.down("xs")]: {
      width: 1024,
      marginLeft: 0,
      marginTop: 56,
      padding: theme.spacing(3),
    },
  },
  contentFullWidth: {
    width: "100%",
    marginLeft: 0,
  },
  trialPanelOffset: {
    paddingTop: 56,
  },
}));

function AppContainer({ hideSideMenu, children }) {
  const classes = useStyles();
  const belowSM = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const { subscription: { in_trial } = {} } = useSelector(
    (state) => state.auth
  );
  const { notFound, accessDenied } = useSelector(
    (state) => state.shared.appRequestStatus
  );

  const [isDrawerOpen, setDrawerOpen] = useState(hideSideMenu || !belowSM);
  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };
  const openDrawer = () => {
    setDrawerOpen(true);
  };

  useEffect(() => {
    setDrawerOpen(!belowSM);
  }, [belowSM]);

  return (
    <div className={classes.root}>
      <AppBar
        component="header"
        position="fixed"
        color="inherit"
        className={classes.appBar}
      >
        <TrialPanel />
        <TopBar
          toggleDrawer={!hideSideMenu ? toggleDrawer : null}
          isDrawerOpen={isDrawerOpen}
        />
        <ProgressBar color="secondary" />
      </AppBar>
      <Toolbar />

      {!hideSideMenu && (
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawer, {
              [classes.drawerOpen]: isDrawerOpen,
              [classes.drawerClose]: !isDrawerOpen,
              [classes.drawerHidden]: hideSideMenu,
              [classes.trialPanelOffset]: in_trial,
            }),
          }}
        >
          <SideMenu openDrawer={openDrawer} isDrawerOpen={isDrawerOpen} />
        </Drawer>
      )}

      <main
        className={clsx({
          [classes.content]: !isDrawerOpen,
          [classes.contentShift]: isDrawerOpen,
          [classes.contentFullWidth]: hideSideMenu,
          [classes.trialPanelOffset]: in_trial,
        })}
      >
        {/** HERE THE APP GOES **/}
        {notFound ? <NotFound /> : accessDenied ? <AccessDenied /> : children}

        <Notification />
      </main>
    </div>
  );
}

AppContainer.propTypes = {
  hideSideMenu: PropTypes.bool,
};

export default AppContainer;

import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../../utils/localStorage";

import { USER_LOGGEDIN } from "../../../../redux/shared/actions/actionTypes";

const prefKeepMe = getLocalStorageItem("k");

const keepMeLoggedReducer = (state = !!prefKeepMe, action) => {
  switch (action.type) {
    case USER_LOGGEDIN:
      // if user wants to be kept logged in, save his pref to local storage
      setLocalStorageItem("k", action.keepMeLogged ? 1 : 0);
      return action.keepMeLogged;

    default:
      return state;
  }
};

export default keepMeLoggedReducer;

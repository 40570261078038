import { forwardRef } from "react";
import PropTypes from "prop-types";

import { Link as RouterLink, useLocation } from "react-router-dom";
import MuiLink from "@material-ui/core/Link";

const Link = forwardRef(
  ({ to, replace, inline, underline, color, children, ...restProps }, ref) => {
    const { pathname } = useLocation();
    const isSamePath = () =>
      typeof to === "string"
        ? to === pathname
        : typeof to === "object"
        ? to.pathname === pathname
        : false;

    return (
      <MuiLink
        ref={ref}
        to={to}
        component={RouterLink}
        replace={replace || isSamePath()}
        underline={underline || "hover"}
        color={color || "primary"}
        display={inline ? "inline" : "block"}
        {...restProps}
      >
        {children}
      </MuiLink>
    );
  }
);

Link.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.object,
    }),
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  replace: PropTypes.bool,
  inline: PropTypes.bool,
  underline: PropTypes.oneOf(["always", "hover", "none"]),
  color: PropTypes.oneOf([
    "error",
    "primary",
    "secondary",
    "textPrimary",
    "textSecondary",
  ]),
};

export default Link;

import {
  ORDERS_DATA_FETCHED,
  RT_ORDER_ASSIGNED,
  RT_ORDER_UPDATED,
  RT_CAPTAIN_UPDATED,
} from "../actions/actionTypes";

const INIT_STATE = {
  captainsIDs: [],
  captains: {},
  ordersIDs: [],
  orders: {},
  captainsOrdersIDs: {},
};

const dataReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ORDERS_DATA_FETCHED:
      const normalized = payload.data.workers.reduce(
        (acc, { orders: workerOrders, ...worker }) => {
          const captainsIDs = [...acc.captainsIDs, worker.id];
          const captains = { ...acc.captains, [worker.id]: worker };

          const ordersIDs = [
            ...acc.ordersIDs,
            ...workerOrders.map((o) => o.id),
          ];
          const orders = {
            ...acc.orders,
            ...workerOrders.reduce((acc, o) => ({ ...acc, [o.id]: o }), {}),
          };

          const captainsOrdersIDs = {
            ...acc.captainsOrdersIDs,
            [worker.id]: workerOrders.map((o) => o.id),
          };

          return {
            captainsIDs,
            captains,
            ordersIDs,
            orders,
            captainsOrdersIDs,
          };
        },
        {
          captainsIDs: [],
          captains: {},
          ordersIDs: [],
          orders: {},
          captainsOrdersIDs: {},
        }
      );

      return normalized;

    case RT_ORDER_ASSIGNED:
      const captainID = payload.captain.id;

      return {
        ...state,
        // add new order id to orders ids
        ordersIDs: [payload.id, ...state.ordersIDs],
        // add new order to orders map
        orders: { [payload.id]: payload, ...state.orders },
        // add new order id to captain's orders
        captainsOrdersIDs: {
          ...state.captainsOrdersIDs,
          [captainID]: [payload.id, ...state.captainsOrdersIDs[captainID]],
        },
        captains: {
          ...state.captains,
          [captainID]: {
            ...state.captains[captainID],
            total_orders: state.captains[captainID].total_orders + 1,
          },
        },
      };

    case RT_ORDER_UPDATED:
      return {
        ...state,
        orders: {
          ...state.orders,
          [payload.id]: { ...state.orders[payload.id], ...payload },
        },
      };

    case RT_CAPTAIN_UPDATED:
      return {
        ...state,
        captains: {
          ...state.captains,
          [payload.id]: { ...state.captains[payload.id], ...payload },
        },
      };

    default:
      return state;
  }
};

export default dataReducer;

export const NOT_FOUND = "NOT_FOUND";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const RESET_REQ_STATUS = "RESET_REQ_STATUS";

export const USER_LOGGEDIN = "USER_LOGGEDIN";
export const USER_LOGGEDOUT = "USER_LOGGEDOUT";
export const LOGGED_USER_FETCHED = "LOGGED_USER_FETCHED";

export const SETTINGS_FETCHED = "SETTINGS_FETCHED";
export const SETTINGS_UPDATED = "SETTINGS_UPDATED";

export const COMPANY_UPDATED = "COMPANY_UPDATED";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const PROFILE_INFO_UPDATED = "PROFILE_INFO_UPDATED";

export const REQUEST_START = "REQUEST_START";
export const REQUEST_END = "REQUEST_END";

export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

export const ORDERS_PAGE_FETCHED = "ORDERS_PAGE_FETCHED";
export const ORDER_ASSIGNED = "ORDER_ASSIGNED";
export const ORDER_DISPATCHED = "ORDER_DISPATCHED";

export const TOOL_CUSTODIES_PAGE_FETCHED = "TOOL_CUSTODIES_PAGE_FETCHED";
export const TOOL_CUSTODY_MOVED = "TOOL_CUSTODY_MOVED";

export const WALLET_CUSTODIES_PAGE_FETCHED = "WALLET_CUSTODIES_PAGE_FETCHED";

export const ALL_USERS_FETCHED = "ALL_USERS_FETCHED";
export const ALL_BRANCHES_FETCHED = "ALL_BRANCHES_FETCHED";
export const ALL_ROLES_FETCHED = "ALL_ROLES_FETCHED";
export const ALL_SHIFTS_FETCHED = "ALL_SHIFTS_FETCHED";
export const ALL_SHELVES_FETCHED = "WAR_ALL_SHELVES_FETCHED";

import { forwardRef } from "react";
import PropTypes from "prop-types";

import MenuItem from "@material-ui/core/MenuItem";

import Link from "./Link";

const IconMenuItem = forwardRef((props, ref) => {
  const { to, children, ...rest } = props;

  return to ? (
    <li>
      <MenuItem ref={ref} component={Link} to={to} {...rest}>
        {children}
      </MenuItem>
    </li>
  ) : (
    <MenuItem ref={ref} {...rest}>
      {children}
    </MenuItem>
  );
});

IconMenuItem.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.object,
    }),
  ]),
  children: PropTypes.element.isRequired,
};

export default IconMenuItem;

import { BRANCHES_PAGE_FETCHED, BRANCH_UPDATED } from "../actions/actionTypes";

const INITIAL_STATE = { metadata: {}, data: [], filters: {} };

const branchesPageReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case BRANCHES_PAGE_FETCHED:
      return payload;

    case BRANCH_UPDATED:
      return {
        ...state,
        data: state.data.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };

    default:
      return state;
  }
};

export default branchesPageReducer;

import { DEFAULT_ROLE_FETCHED } from "../actions/actionTypes";

const INIT_STATE = {
  role_name: "",
  role_name_ar: "",
  mobile_access: false,
  role_permissions: {},
};

const defaultRoleReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case DEFAULT_ROLE_FETCHED:
      return { ...state, role_permissions: payload };

    default:
      return state;
  }
};

export default defaultRoleReducer;

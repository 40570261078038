import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { useHistory, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import oopsSvg from "../../../../assets/error/oops.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(5, 3),
  },
  img: {
    flexGrow: 1,
    width: 380,
    height: 380,
  },
  message: {
    marginTop: theme.spacing(2),
  },
}));

function ErrorFallback({ resetError }) {
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();
  const { t } = useTranslation("common");

  /**
   * NOTE:
   * a race condition was detected here between two functions that
   * must run one after another! the app should FIRST navigate away
   * from the route that caused the error, then reset the error boundary
   * to navigate to a different route.
   *
   * the ref maintain the error route path, when this path changes, reset the
   * error boundary.
   */
  const errorPath = useRef(location.pathname);
  useEffect(() => {
    if (location.pathname !== errorPath.current) {
      resetError();
    }
  }, [location.pathname, resetError]);

  return (
    <div className={classes.root}>
      <img className={classes.img} alt="error" src={oopsSvg} />

      <div className={classes.message}>
        <Typography component="h1" variant="h4" color="primary" gutterBottom>
          {t("something went wrong")}
        </Typography>
        <Typography
          component="p"
          variant="body1"
          color="textSecondary"
          gutterBottom
        >
          {t("error message")}
        </Typography>

        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => history.goBack()}
        >
          {t("go back")}
        </Button>
      </div>
    </div>
  );
}

ErrorFallback.propTypes = {
  resetError: PropTypes.func.isRequired,
};

export default ErrorFallback;

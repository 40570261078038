import { SETTINGS_FETCHED, SETTINGS_UPDATED } from "../actions/actionTypes";

const INIT_STATE = {
  currency: "",
  color: "",
  logo: "",

  max_wallet: null,

  trip_prices: null, // [{ from: 0, to: 5, price: 100 }]
  delivery_deduced: false,
  return_deduced: false,
  order_max_time: "",

  webhook_url: "",
  webhook_secret: "",
  webhook_events: [],
};

function sortPrices(prices) {
  return prices.sort((a, b) => (a.from > b.from ? 1 : -1));
}

const settingsReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SETTINGS_FETCHED:
      return {
        ...payload,
        trip_prices: sortPrices(payload.trip_prices),
      };

    case SETTINGS_UPDATED:
      return {
        ...state,
        ...payload,
        trip_prices: sortPrices(payload.trip_prices),
      };

    default:
      return state;
  }
};

export default settingsReducer;

import { combineReducers, compose, applyMiddleware, createStore } from "redux";
import reduxThunk from "redux-thunk";

import auth from "./shared/reducers/auth";
import shared from "./shared/reducers";

import app from "../modules/App/state/reducers";
import dashboard from "../modules/Dashboard/state/reducers";
import orders from "../modules/Orders/state/reducers";
import branches from "../modules/Branches/state/reducers";
import warehouse from "../modules/Warehouse/state/reducers";
import users from "../modules/Users/state/reducers";
import wallets from "../modules/Wallets/state/reducers";
import reports from "../modules/Reports/state/reducers";
import settings from "../modules/Settings/state/reducers";

const rootReducer = combineReducers({
  auth,
  shared,

  app,
  dashboard,
  orders,
  warehouse,
  wallets,
  users,
  reports,
  settings,
  branches,
});

const composeEnhancers =
  process.env.NODE_ENV === "production"
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(reduxThunk))
);

export default store;

import { useState } from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
  menu: {
    minWidth: 200,
    maxWidth: 300,
  },
  buttonDefault: {
    width: 36.5,
    height: 36.5,
    color: "#586285",
    border: "1px solid #edf5fc",
    backgroundColor: "#f9fbfe",
    "&:hover": {
      backgroundColor: "#f9fbfe",
    },
  },
  buttonOutlined: {
    padding: 5,
    borderRadius: 5,
  },
  buttonLarge: {
    width: 38.25,
    height: 38.25,
  },
  buttonSmall: {
    width: 24,
    height: 24,
  },
  buttonOpened: {
    borderColor: theme.palette.secondary.main,
  },
}));

function IconMenu({
  label,
  icon,
  edge,
  small,
  large,
  outlined,
  badge,
  disabled,
  disableScroll,
  children,
}) {
  const theme = useTheme();
  const classes = useStyles();

  const [menuButton, setMenuButton] = useState(null);

  // render helpers
  const renderIcon = () => (
    <SvgIcon
      fontSize={small ? "small" : "medium"}
      color={!!menuButton ? "secondary" : "inherit"}
    >
      <path d={icon} />
    </SvgIcon>
  );
  const renderIconWithBadge = (badgeContent) => (
    <Badge badgeContent={badgeContent} color="secondary">
      {renderIcon()}
    </Badge>
  );

  return (
    <>
      <IconButton
        aria-label={`show ${label} menu`}
        aria-controls="menu"
        aria-haspopup="true"
        edge={edge}
        disabled={disabled}
        className={clsx(classes.buttonDefault, {
          [classes.buttonOutlined]: outlined,
          [classes.buttonLarge]: large,
          [classes.buttonSmall]: small,
          [classes.buttonOpened]: !!menuButton,
        })}
        onClick={(e) => {
          e.stopPropagation();
          setMenuButton(e.currentTarget);
        }}
      >
        {badge ? renderIconWithBadge(badge) : renderIcon()}
      </IconButton>

      <Menu
        id="menu"
        aria-label={`${label} menu`}
        classes={{ paper: classes.menu }}
        anchorEl={menuButton}
        anchorOrigin={{
          // point on menuButton to drop menu from
          vertical: "bottom",
          horizontal: theme.direction === "rtl" ? "left" : "right",
        }}
        transformOrigin={{
          // point of menu to be mounted on anchor
          vertical: "top",
          horizontal: theme.direction === "rtl" ? "left" : "right",
        }}
        elevation={1}
        open={Boolean(menuButton)}
        onClose={(e) => {
          e.stopPropagation();
          setMenuButton(null);
        }}
        getContentAnchorEl={null}
        disableScrollLock={!disableScroll}
      >
        {children}
      </Menu>
    </>
  );
}

IconMenu.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  edge: PropTypes.oneOf(["start", "end"]),
  small: PropTypes.bool,
  large: PropTypes.bool,
  outlined: PropTypes.bool,
  badge: PropTypes.number,
  disabled: PropTypes.bool,
  disableScroll: PropTypes.bool,
};

export default IconMenu;

import PropTypes from "prop-types";

import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "fixed",
    zIndex: theme.zIndex.appBar,
  },
}));

function ProgressBar({ color }) {
  const classes = useStyles();

  const active = useSelector((state) => state.shared.appRequests.active);

  return active ? (
    <LinearProgress color={color} className={classes.root} />
  ) : null;
}

ProgressBar.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary"]),
};

export default ProgressBar;

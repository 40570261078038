const en = {
  orders: "Orders",
  branch: "Branch",
  captain: "Captain",
  date: "Date",
  today: "Today",
  payment: "Payment",
  reason: "Reason: ",
  EGP: "EGP",
  SAR: "SAR",
  type: "Type",
  id: "ID",
  status: "Status",
  actions: "Actions",
  dispatch: "Dispatch",
  dispatching: "Dispatching",
  assign: "Assign",
  idle: "Idle",
  max_mins_count: "Maximum {{count}} minutes",
  mins_amount: "{{amount}}mins",
  mins_delay_amount: "{{amount}}mins delay",

  "todays data": "Today's data, change date for different data",
  "delivered orders": "Delivered Orders",
  "returned orders": "Returned Orders",
  "active captains": "Active Captains",
  "idle captains": "Idle Captains",
  "change delay buffer": "Change Delay Buffer",
  "delay buffer": "Delay Buffer",
  "delivery captains": "Delivery Captains",
  "captain information": "Captain Information",
  "wallet custody": "Wallet Custody",
  "order cost": "Order Cost",
  "delivery cost": "Delivery Cost",
  "return cost": "Return Cost",
  "payment type": "Payment Type",
  "packages no": "Packages No.",
  "order start": "Order Start",
  "order delivery": "Order Delivery",
  "order pickup": "Order Pickup",
  "back to store": "Back to Store",
  "order cancelled": "Order Cancelled",
  "order rejected": "Order Rejected",
  "by customer": "By Customer",
  "order number": "Order Number",
  "start time": "Start Time",
  "request time": "Request Time",
  "trip cost": "Trip Cost",
  "going/coming": "Going | Coming",
  "add order title": "Add New Delivery Order",
  "return order title": "Return Order",
  "customer name": "Customer Name",
  "customer phone": "Customer Phone",
  "customer address": "Customer Address",
  "customer notes": "Customer Notes",
  "pick customer location": "Pick customer location",
  "trip fees applied?": "Trip fees applied?",
  "orders history": "Orders History",
  "start date": "Start Date",
  "end date": "End Date",
  "order details": "Order Details",
  "not assigned": "Not Assigned",
  "idle captains only": "Idle captains only",
  "assign order to captain": "Assign Order to Captain",
  "todays orders": "Today's Orders",

  "no orders": "There's no orders yet",
  "no branches": "There's no branches yet",
  "no captains": "There's no captains yet",
  "no idle captains": "There's no idle captains now",
  "please select captain": "Please select captain",

  "invalid number": "Invalid number",
  "cannot exceed trip max duration": "Cannot exceed single trip max duration",
  "required field": "This field is required",
  "too short name": "Too short name, should be 2 letters at least",
  "too long name": "Too long name, should be 50 letters at most",
  "too short address": "Too short address, should be 10 letters at least",
  "too long address": "Too long address, should be 100 letters at most",
  "too long note": "Too long note, should be 300 letters at most",
  "invalid branch": "Invalid branch",
  "invalid captain": "Invalid captain",
  "invalid phone": "Invalid phone",
  "invalid payment": "Invalid payment",
  "position required": "Position required",
  "invalid date": "Invalid date",
  "start date after end date": "Start date is after End date",
  "end date before start date": "End date is before Start date",
  "location outside area":
    "Customer location should be inside branch coverage area",

  add: "Add Order",
  save: "Save",
  clear: "Clear",
  "return order": "Return Order",
  "assign order": "Assign Order",

  ACTIVE: "Active",
  IDLE: "Idle",
  OFF_SHIFT: "Off shift",
  VACATION: "Vacation",
  BREAK: "Break",
  MAX_WALLET: "Wallet Max",

  DELIVERY: "Delivery",
  RETURN: "Return",

  NEW: "New",
  STARTED: "Started",
  DELIVERED: "Delivered",
  PICKED_UP: "Picked up",
  CANCELLED: "Cancelled",
  RETURNED: "Returned",

  CASH: "Cash",
  CARD: "Card",
  PAID: "Paid",

  CAPTAIN: "By Captain",
  CUSTOMER: "By Customer",
  OPERATOR: "By Management",
  UNKNOWN: "Unknown",
  MAX_TIME_EXCEEDED: "Not Assigned",
};

export default en;

import { useState, useEffect, forwardRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import hideNotification from "../../../redux/shared/actions/hideNotification";

import { useTranslation } from "react-i18next";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.success.dark,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function Notification() {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation("feedback");

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const notification = useSelector((state) => state.shared.notification);

  useEffect(() => {
    !!notification.message && setOpen(true);
  }, [notification]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={8000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: theme.direction === "rtl" ? "left" : "right",
      }}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
      transitionDuration={400}
      TransitionProps={{ onExited: () => dispatch(hideNotification()) }}
    >
      <SnackbarContent
        classes={{ root: classes[notification.variant] }}
        message={t(notification.message)}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        }
      />
    </Snackbar>
  );
}

export default Notification;

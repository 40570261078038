const en = {
  owner: "Owner",
  edit: "Edit",
  cancel: "Cancel",
  save: "Save",
  email: "Email",
  phone: "Phone",
  password: "Password",
  "my profile": "My Profile",
  "edit profile": "Edit Profile",
  "profile info": "Profile Information",
  "full name": "Full Name",
  "user image": "User Image",
  "change password": "Change Password",
  "old password": "Old Password",
  "new password": "New Password",
  "confirm password": "Confirm Password",
  "required field": "This field is required",
  "too short name": "Too short name, should be 5 letters at least",
  "too long name": "Too long name, should be 25 letters at most",
  "invalid email": "Invalid Email",
  "invalid phone": "Invalid phone number",
  "two passwords not matching": "The new passwords don't match",
  "new password matches old passowrd":
    "The new password should not match old one",
  "old password is wrong": "Old password is wrong",
  "required six characters": "Password should be 6 characters at least",
  "password hint":
    "For strong password use:\n- Special characters\n- Numbers, Capital letters, and Small letters",
};

export default en;

import { ORDERS_DATA_FETCHED, RT_ORDER_ASSIGNED } from "../actions/actionTypes";

const INIT_STATE = [[], []];

const getBounds = (positions) => {
  const refPosition = positions.shift();
  if (!refPosition) return INIT_STATE;

  let minLat = parseFloat(refPosition.lat);
  let maxLat = parseFloat(refPosition.lat);
  let minLng = parseFloat(refPosition.lng);
  let maxLng = parseFloat(refPosition.lng);

  positions.forEach((pos) => {
    const lat = parseFloat(pos.lat);
    const lng = parseFloat(pos.lng);

    if (lat > maxLat) maxLat = lat;
    if (lat < minLat) minLat = lat;

    if (lng > maxLng) maxLng = lng;
    if (lng < minLng) minLng = lng;
  });

  return [
    { lat: minLat, lng: maxLng }, // corner
    { lat: maxLat, lng: minLng }, // corner
  ];
};

const mapBoundsReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ORDERS_DATA_FETCHED:
      // array of delivery captains' positions
      const captainsPositions = payload.data.workers.map((w) => w.coordinates);

      // array of orders customers' positions
      const customersPositions = payload.data.workers.reduce((acc, w) => {
        const locations = w.orders.map((o) => o.customer.location);

        return [...acc, ...locations];
      }, []);

      return getBounds([...captainsPositions, ...customersPositions]);

    case RT_ORDER_ASSIGNED:
      return getBounds([...state, payload.customer.location]);

    default:
      return state;
  }
};

export default mapBoundsReducer;

const en = {
  reports: "Reports",
  report: "Report",
  customers: "Customers",
  workers: "Workers",
  products: "Products",
  visits: "Visits",
  save: "Save",
  send: "Send",
  cancel: "Cancel",
  creator: "Creator",
  from: "From",
  to: "To",
  actions: "Actions",
  status: "Status",
  type: "Type",
  add: "Add",
  "created by": "Created by {{name}}",
  "created on": "Created on",
  "add report": "Add Report",
  "from date": "From Date",
  "to date": "To Date",
  "create new report": "Create New Report",
  "send report by email": "Send Report by Email",
  "download report": "Download Report",
  "email number": "Email {{num}}",
  "report name ar": "Report Arabic Name",
  "report name en": "Report English Name",
  "report details": "Report Details",
  "report options": "Report Options",
  "visits options": "Visits Options",
  "products options": "Products Options",
  "no reports": "There's no reports yet",
  "no customers": "There's no customers yet",
  "no users": "There's no users yet",
  "no products": "There is no products yet",
  "required field": "This field is required",
  "too short name": "Too short name, should be 2 letters at least",
  "too long name": "Too long name, should be 50 letters at most",
  "invalid date": "Invalid Date",
  "date should not be in future": "Date should not be in future",
  "start date after end date": "Start date is after End date",
  "end date before start date": "End date is before Start date",
  "invalid customer": "Invalid Customer",
  "invalid worker": "Invalid Worker",
  "invalid product": "Invalid Product",
  "invalid user": "Invalid User",
  "invalid email": "Invalid Email",
  "repeated email": "This Email is repeated",

  PRODUCTS: "Products",
  VISITS: "Visits",

  MISSED_VISITS: "Missed Visits",
  FINISHED_VISITS: "Finished Visits",
  CANCELLED_VISITS: "Cancelled Visits",
  DELAYED_VISITS: "Delayed Visits",

  PRODUCTS_CUSTODY: "Products Custody",
  PRODUCTS_STORE: "Products Store",

  NEW: "New",
  "IN-PROGRESS": "In Porgress",
  FINISHED: "Finished",
  ERROR: "Error",
};

export default en;

import { ALL_SHELVES_FETCHED } from "../actions/actionTypes";

const allShelvesReducer = (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case ALL_SHELVES_FETCHED:
      return payload;

    default:
      return state;
  }
};

export default allShelvesReducer;

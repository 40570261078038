import { SHELVES_PAGE_FETCHED, SHELF_UPDATED } from "../actions/actionTypes";

const INIT_STATE = { metadata: {}, data: [], filters: {} };

const shelvesPageReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SHELVES_PAGE_FETCHED:
      return payload;

    case SHELF_UPDATED:
      return {
        ...state,
        data: state.data.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };

    default:
      return state;
  }
};

export default shelvesPageReducer;

const en = {
  "branch added": "Branch added successfully",
  "branch updated": "Branch updated successfully",
  "captain moved": "Captain moved successfully",
  "captain removed": "Captain removed successfully",

  "order added": "Order added successfully",
  "order assigned": "Order assigned successfully",
  "order dispatched": "Order dispatched successfully",

  "tool added": "Tool created successfully",
  "tool updated": "Tool updated successfully",
  "tool moved": "Tool moved successfully",
  "shelf added": "Shelf created successfully",
  "shelf updated": "Shelf updated successfully",

  "custody added": "Custody added successfully",

  "report added": "Report added successfully",
  "report sent": "Report sent successfully",

  "settings updated": "Settings updated successfully",
  "shift added": "Shift added successfully",
  "shift updated": "Shift updated successfully",
  "role added": "Role added successfully",
  "role updated": "Role updated successfully",
  "user role updated": "User role updated successfully",

  "user added": "User added successfully",
  "user updated": "User updated successfully",

  "company updated": "Company updated successfully",

  "profile updated": "Profile updated successfully",
  "password changed": "Password changed successfully",

  "something went wrong": "Something went wrong, please try again",
  "network error": "Network error, please try again",
};

export default en;

import { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { intervalToDuration, formatDuration } from "date-fns";
import { DATEFNS_LOCALE } from "../../../constants/datetime";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 56,
    color: "#FFF",
    padding: theme.spacing(0, 3),
    zIndex: theme.zIndex.appBar,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background:
      "linear-gradient(90deg, #FFC404 0%, #FEBC5E 19%, #FF5D5A 87%, #FE7E59 100%)",
  },
  button: {
    marginLeft: theme.spacing(2),
    borderRadius: 100,
    color: "#FF5D5A",
    backgroundColor: "#FFF",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
}));

function TrialPanel() {
  const classes = useStyles();
  const { t, i18n } = useTranslation("app");

  const { in_trial, trial_ended, trial_ends_at, purchase_link } = useSelector(
    (state) => state.auth.subscription
  );

  const [counter, setCounter] = useState(0);
  useEffect(() => {
    if (!in_trial || trial_ended) return;

    const timeoutID = setTimeout(() => setCounter(counter + 1), 60e3);

    return () => clearTimeout(timeoutID);
  }, [counter, in_trial, trial_ended]);

  if (!in_trial || trial_ended) return null;

  return (
    <div className={classes.root}>
      <Typography variant="body1">
        {t("on trial plan")}{" "}
        {formatDuration(
          intervalToDuration({
            start: new Date(),
            end: new Date(trial_ends_at),
          }),
          {
            zero: true,
            delimiter: ", ",
            locale: DATEFNS_LOCALE[i18n.language],
            format: ["days", "hours", "minutes"],
          }
        )}
      </Typography>

      <Button disableElevation className={classes.button} href={purchase_link}>
        {t("start premium")}
      </Button>
    </div>
  );
}

export default TrialPanel;

import { USER_FETCHED, USER_UPDATED } from "../actions/actionTypes";

const singleUserReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_FETCHED:
      return payload;

    case USER_UPDATED:
      return state.id === payload.id ? { ...state, ...payload } : state;

    default:
      return state;
  }
};

export default singleUserReducer;

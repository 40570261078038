import { ORDERS_STATS_FETCHED } from "../actions/actionTypes";

const INIT_STATE = { data: {}, filters: {} };

const ordersStatsReucer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ORDERS_STATS_FETCHED:
      return payload;

    default:
      return state;
  }
};

export default ordersStatsReucer;

import { combineReducers } from "redux";

import systemStats from "./systemStats";
import ordersStats from "./ordersStats";
import topCaptains from "./topCaptains";
import topBranches from "./topBranches";
import toolsCustody from "./toolsCustody";
import queue from "./queue";

export default combineReducers({
  systemStats,
  ordersStats,
  topCaptains,
  topBranches,
  toolsCustody,
  queue,
});

const en = {
  wallets: "Wallets",
  type: "Type",
  amount: "Amount",
  order: "Order",
  date: "Date",
  EGP: "EGP",
  SAR: "SAR",
  total: "Total",

  "wallet custody": "Wallet Custody",
  "order number": "Order Number",
  "delivery cost": "Delivery Cost",
  "return cost": "Return Cost",
  "required amount": "Required Amount",

  "no workers": "There is no captains with money custody",
  "no custody": "No money custody with captain yet",
  "no custody for worker": "There is no custody for {{name}} yet",

  confirm: "Confirm",
  search: "Search",
  "add custody": "Add Custody",
  "return all": "Retrun All",

  "confirm custody return": "Confirm All Custody Return",
  "confirm custody return message":
    "Are you sure you want to return all money custody from the captain?",

  "required field": "This field is required",
  "max 36 characters": "36 characters at most",
  "invalid order": "Invalid order",
  "return orders allowed": "Only return orders allowed",

  DELIVERY_ORDER: "Delivery Order",
  CUSTODY_ADD: "Custody Addition",
};

export default en;

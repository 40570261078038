import { ORDERS_DATA_FETCHED, SET_DELAY_BUFFER } from "../actions/actionTypes";

import { startOfToday, startOfDay } from "date-fns";

const INIT_STATE = {
  day: startOfToday().getTime(),
  branch: "",
  intervalMins: 10,
  intervalWidth: 100,
  tripMins: 20,
  delayBuffer: 0,
};

const configsReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ORDERS_DATA_FETCHED:
      const { day, branch } = payload.filters;
      return {
        ...state,
        day: startOfDay(new Date(day)).getTime(),
        branch,
      };

    case SET_DELAY_BUFFER:
      return { ...state, delayBuffer: payload };

    default:
      return state;
  }
};

export default configsReducer;

import { WALLET_CUSTODIES_PAGE_FETCHED } from "../actions/actionTypes";

const INITIAL_STATE = { metadata: {}, data: [], filters: {} };

const walletCustodiesPageReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case WALLET_CUSTODIES_PAGE_FETCHED:
      return payload;

    default:
      return state;
  }
};

export default walletCustodiesPageReducer;

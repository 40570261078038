import { apiGetSettings } from "../../apis/SharedClient";

import createApiAction from "./base/createApiAction";
import { SETTINGS_FETCHED, SHOW_NOTIFICATION } from "./actionTypes";

/**
 * Async action creator to fetch System Settings
 */
const fetchSettings = () => async (dispatch, getState) => {
  const executeRequest = async (token) => {
    return apiGetSettings(token);
  };

  const handleResponse = ({ status_code, data }) => {
    switch (status_code) {
      case 200:
        dispatch({ type: SETTINGS_FETCHED, payload: data });
        break;

      default:
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { variant: "error", message: "something went wrong" },
        });
        break;
    }
  };

  createApiAction(dispatch, getState, executeRequest, handleResponse);
};

export default fetchSettings;

import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

import ErrorFallback from "./ErrorFallback";

function ErrorBoundary({ children }) {
  return (
    <SentryErrorBoundary
      fallback={({ resetError }) => <ErrorFallback resetError={resetError} />}
    >
      {children}
    </SentryErrorBoundary>
  );
}

export default ErrorBoundary;

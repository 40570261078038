import { USER_LOGS_PAGE_FETCHED } from "../actions/actionTypes";

const INIT_STATE = { metadata: {}, data: [] };

const userLogsPageReucer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_LOGS_PAGE_FETCHED:
      return payload;

    default:
      return state;
  }
};

export default userLogsPageReucer;

const ar = {
  "go back": "رجوع",
  "not found": "غير موجود",
  "not found message": "لا نستطيع إيجاد الصفحة التي تبحث عنها.",
  "access denied": "غير مُصرَّح",
  "access denied message": "ليس لديك الصلاحية لرؤية هذه الصفحة.",
  "something went wrong": "!حدث عطل ما",
  "error message": "يبدو أنه حدث عطل ما، نحن نعمل على إصلاحه في أقرب وقت.",
  "no content": "لا يوجد شيء للعرض هنا، حتى الآن.",

  browse: "تصفّح",
  "dnd image": "إسحب وضع الصورة",
  "dnd file": "إسحب وضع الملف",
  "dnd files": "إسحب وضع الملفات",

  ",": " ،",
  "invalid image extension":
    "يجب أن يكون إمتداد الصورة واحد من ( {{validExt}} )",
  "invalid image size": "يجب أن يكون حجم الصورة {{validSize}}MB  على الأقصى",

  keyword: "كلمة البحث",
  "too long keyword": "يجب أن تكون 25 حرف على الأكثر",

  location: "الموقع",
  "location keywords": "كلمات البحث عن الموقع",
  "type keywords to search": "اكتب كلمات للبحث في الخريطة",
  "cannot find addresses": "لا يمكن إيجاد عنواين، اعد التجربة بكلمات جديدة",

  "drop file or browse": "إسحب الملف هنا أو تصفح لرفعه",
  "file uploaded successfully": "تم رفع الملف بنجاح",
};

export default ar;

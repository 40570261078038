import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../../../utils/localStorage";
import {
  setSessionStorageItem,
  getSessionStorageItem,
  removeSessionStorageItem,
} from "../../../utils/sessionStorage";

import {
  USER_LOGGEDIN,
  USER_LOGGEDOUT,
  LOGGED_USER_FETCHED,
  PROFILE_INFO_UPDATED,
  COMPANY_UPDATED,
} from "../actions/actionTypes";

const token = getLocalStorageItem("t") || getSessionStorageItem("t");

const INIT_STATE = { access_token: token || "" };

const authReducer = (state = INIT_STATE, action) => {
  const { type, payload, keepMeLogged } = action;

  switch (type) {
    case USER_LOGGEDIN:
      // if user wants to be kept logged in, save his data to local storage
      keepMeLogged && setLocalStorageItem("t", payload.access_token);
      setSessionStorageItem("t", payload.access_token);

      return { ...state, ...payload };

    case USER_LOGGEDOUT:
      // if user is there in local storage, remove it
      !!getLocalStorageItem("t") && removeLocalStorageItem("t");
      !!getSessionStorageItem("t") && removeSessionStorageItem("t");

      return {};

    case LOGGED_USER_FETCHED:
      return { ...state, ...payload };

    case PROFILE_INFO_UPDATED:
      return { ...state, user: payload };

    case COMPANY_UPDATED:
      return { ...state, company: payload };

    default:
      return state;
  }
};

export default authReducer;

import { useEffect, lazy, Suspense } from "react";

import initHotjar from "../../utils/initHotjar";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import loggedout from "../../redux/shared/actions/loggedout";
import fetchSettings from "../../redux/shared/actions/fetchSettings";
import fetchLoggedUser from "../../redux/shared/actions/fetchLoggedUser";

import AppTheme from "./components/AppTheme";
import AppContainer from "./components/AppContainer";
import ErrorBoundary from "./components/ErrorBoundary";

const Profiles = lazy(() => import("../Profiles"));

const AppRouting = lazy(() => import("./routing/AppRouting"));
const PublicRouting = lazy(() => import("./routing/PublicRouting"));

const CompanySetup = lazy(() => import("./views/CompanySetup"));
const RenewSubscription = lazy(() => import("./views/RenewSubscription"));

function App() {
  const dispatch = useDispatch();
  const {
    user,
    access_token: token,
    company: { setup_completed } = {},
    subscription: { trial_ended, plan_ended } = {},
  } = useSelector((state) => state.auth);
  const settings = useSelector((state) => state.shared.settings);

  useEffect(() => {
    if (token && !user?.id) {
      dispatch(fetchLoggedUser());
    }
  }, [dispatch, token, user]);

  useEffect(() => {
    if (token && !Object.values(settings).length) {
      dispatch(fetchSettings());
    }
  }, [dispatch, token, settings]);

  useEffect(() => {
    // initialize hotjar
    initHotjar();

    /**
     * window event listener; to keep an eye on local storage.
     * used for multi tab to catch any logout happens in any tab
     * and logs out from any other opened tab
     */
    window.addEventListener("storage", (e) => {
      if (e.key === "t" && e.newValue === null) {
        dispatch(loggedout());
      }
    });
  }, [dispatch]);

  // if there's a token and no user object found, don't render, wait till fetch ^
  if (token && !user) return null;

  const appBlocked = !setup_completed || trial_ended || plan_ended;

  return (
    <AppTheme>
      <Router>
        <Suspense fallback={<></>}>
          {user ? (
            <AppContainer hideSideMenu={appBlocked}>
              <ErrorBoundary>
                <Switch>
                  <Route path="/profiles" component={Profiles} />

                  {!setup_completed ? (
                    <CompanySetup />
                  ) : trial_ended || plan_ended ? (
                    <RenewSubscription />
                  ) : (
                    <AppRouting />
                  )}

                  <Redirect to="/" />
                </Switch>
              </ErrorBoundary>
            </AppContainer>
          ) : (
            <ErrorBoundary>
              <PublicRouting />
            </ErrorBoundary>
          )}
        </Suspense>
      </Router>
    </AppTheme>
  );
}

export default App;

import { createTheme, alpha } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import blueGrey from "@material-ui/core/colors/blueGrey";

import { LANGS } from "../../../constants/languages";

import NotoNaskhTtf from "../../../assets/fonts/NotoNaskh-Regular.ttf";

const notoNaskh = {
  fontFamily: "Noto",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('NotoNaskh-Regular'),
    url(${NotoNaskhTtf}) format('truetype')
  `,
};

/**
 * Creates custom Material UI theme
 * @param {Object} configs - theme configurations
 * @param {string} configs.lang - app language
 * @param {string} configs.dir - app direction
 * @param {string} configs.secondaryColor - theme secondary color
 *
 * @returns {Object} Material UI theme
 */
function makeTheme({ lang, dir, secondaryColor }) {
  return createTheme({
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [notoNaskh],
          body: {
            overflowX: "hidden",
          },
        },
      },
      MuiButton: {
        root: {
          textTransform: "none",
          fontWeight: 400,
        },
      },
      MuiLink: {
        root: {
          "&.MuiTypography-colorPrimary": {
            color: "#659ACB",
          },
        },
      },
      // overrides the autofill styling
      MuiInputBase: {
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0px 1000px #FAFAFA inset",
          },
        },
        inputMarginDense: {
          height: 21,
        },
      },
      MuiTabs: {
        root: {
          position: "relative",
          border: "none",
          marginBottom: 24,
          zIndex: 1,
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: 2,
            backgroundColor: "#EDF5FC",
            zIndex: -1,
          },
        },
      },
      MuiTab: {
        root: {
          minWidth: 80,
          textTransform: "none",
          "@media (min-width: 600px)": {
            minWidth: 80,
          },
        },
        textColorSecondary: {
          color: "#242E42",
        },
      },
      MuiPaper: {
        elevation1: {
          boxShadow: "0px 2px 4px #ADB5D15C",
        },
      },
      MuiAccordion: {
        root: {
          "&:before": {
            backgroundColor: "#EDF5FC",
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          maxWidth: "none",
        },
      },
      MuiListSubheader: {
        root: {
          lineHeight: 1,
          marginBottom: 8,
        },
      },
      MuiChip: {
        root: {
          height: 28,
          backgroundColor: "#EDF5FC",
          "&:not(:last-child)": { marginRight: 4 },
          "& .MuiChip-avatar": {
            marginLeft: 2,
            width: 26,
            height: 26,
          },
        },
        deleteIcon: {
          width: 16,
          height: 16,
          color: "#242E42",
          "&:hover": {
            color: alpha("#242E42", 0.8),
          },
        },
      },
      MuiStepLabel: {
        label: {
          "&.MuiStepLabel-alternativeLabel": {
            marginTop: 8,
          },
        },
      },
    },
    typography: {
      fontFamily:
        lang === LANGS.Ara
          ? '"Noto", "Helvetica", "Arial", sans-serif'
          : '"Roboto", "Helvetica", "Arial", sans-serif',
      h5: {
        fontWeight: 500,
      },
      subtitle1: {
        fontWeight: 500,
      },
    },
    palette: {
      primary: {
        main: "#242E42",
      },
      secondary: {
        main: secondaryColor || "#FFB300",
      },
      success: {
        main: "#6BD5B1",
      },
      warning: {
        main: "#F6A11D",
      },
      error: {
        main: "#E85C5C",
      },
      background: {
        default: "#FAFBFF",
      },
      text: {
        primary: "#242E42",
        secondary: "#586285",
      },
      action: {
        active: "#586285",
      },
      divider: "#EDF5FC",
      grey,
      blueGrey,
    },
    direction: dir,
  });
}

export default makeTheme;

import { ORDERS_DATA_FETCHED, RT_STATS_UPDATED } from "../actions/actionTypes";

const INIT_STATE = {
  delivery_orders: 0,
  return_orders: 0,
  active_captains: 0,
  idle_captains: 0,
};

const statsReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ORDERS_DATA_FETCHED:
      return payload.data.statistics;

    case RT_STATS_UPDATED:
      return payload;

    default:
      return state;
  }
};

export default statsReducer;

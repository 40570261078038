import { SET_NOW } from "../actions/actionTypes";

const nowReducer = (state = Date.now(), action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_NOW:
      return payload;

    default:
      return state;
  }
};

export default nowReducer;

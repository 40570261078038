export const setSessionStorageItem = (key, value) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    //TODO: report errors
  }
};

export const getSessionStorageItem = (key) => {
  try {
    const serializedItem = sessionStorage.getItem(key);
    return serializedItem ? JSON.parse(serializedItem) : undefined;
  } catch (error) {
    //TODO: report errors
    return undefined;
  }
};

export const removeSessionStorageItem = (key) => {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    //TODO: report errors
  }
};

const en = {
  warehouse: "Warehouse",
  tools: "Tools",
  tool: "Tool",
  custody: "Custody",
  history: "History",
  worker: "Worker",
  user: "User",
  actions: "Actions",
  brand: "Brand",
  date: "Date",
  shelves: "Shelves",
  shelf: "Shelf",
  branch: "Branch",

  "search tools": "Search Tools",
  "search shelves": "Search shelves",
  "add tool": "Add New Tool",
  "edit tool": "Edit Tool",
  "clone tool": "Clone New Tool",
  "add shelf": "Add New Shelf",
  "edit shelf": "Edit Shelf",
  "clone shelf": "Clone New Shelf",
  "operation for": "Operation For",
  "created by": "Created By",
  "tool name ar": "Tool Arabic Name",
  "tool name en": "Tool English Name",
  "tool image": "Tool Image",
  "shelf name ar": "Shelf Arabic Name",
  "shelf name en": "Shelf English Name",
  "shelf image": "Shelf Image",
  "shelf number": "Shelf Number",
  "shelf code": "Shelf Code",
  "download barcode": "Download Barcode",
  "serial number": "Serial Number",
  "model number": "Model Number",
  "tool transfer": "Tool Transfer",
  "to worker": "To worker",
  "to shelf": "To shelf",
  "tools custodies": "Tools Custodies",
  "pos machine": "POS Machine",
  "download barcodes pack": "Download Barcodes Pack",
  "please select branch": "Please select branch to download its barcodes pack.",

  "nothing to show": "No more information to show",
  "no tools": "There's no tools yet",
  "no shelves": "There's no shelves yet",
  "no branches": "There's no branches yet",
  "no captains": "There's no captains yet",
  "no custody for worker": "There is no custodies for {{workerName}} yet",

  save: "Save",
  cancel: "Cancel",
  "save & add": "Save & Add",
  "b add tool": "Add Tool",
  "b edit tool": "Edit Tool",
  "b clone tool": "Clone Tool",
  "move tool": " Move Tool",
  "b add shelf": "Add Shelf",
  "b edit shelf": "Edit Shelf",
  "b clone shelf": "Clone Shelf",
  "download pack": "Download Pack",
  download: "Download",

  "required field": "This field is required",
  "too short name": "Too short name, should be 2 letters at least",
  "too long name": "Too long name, should be 50 letters at most",
  "invalid branch": "Invalid branch",
  "invalid shelf": "Invalid shelf",
  "invalid captain": "Invalid captain",
  "branch has no shelves": "Branch has no shelves",
};

export default en;

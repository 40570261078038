const ar = {
  user: "المستخدم",
  username: "إسم المستخدم",
  users: "المستخدمون",
  email: "البريد الإلكتروني",
  phone: "الهاتف",
  password: "كلمة المرور",
  role: "الوظيفة",
  branch: "الفرع",
  shift: "الشيفت",
  ranking: "التصنيف",
  orders: "الطلبات",
  tools: "المعدات",
  wallet: "المحفظة",
  actions: "الخيارات",
  action: "الحدث",
  from: "من",
  to: "إلى",
  duration: "المدة",
  active: "نشِط",
  search: "إبحث عن مستخدم",
  EGP: "ج.م",
  SAR: "ر.س",
  km_amount: "{{amount}} كم",
  mins_amount: "{{amount}}دق",

  "activity log": "سجل الحركة",
  "add user": "إضافة مستخدم جديد",
  "edit user": "تعديل مستخدم",
  "clone user": "إستنساخ مستخدم جديد",
  "full name": "الإسم كامل",
  "user details": "تفاصيل المستخدم",
  "user role": "وظيفة المستخدم",
  "user image": "صورة المستخدم",
  "delivery orders": "طلبات التوصيل",
  "return orders": "طلبات الإرتجاع",
  "orders from": "الطلبات من",
  "orders to": "الطلبات إلى",
  "off day": "يوم أجازة",

  "no users": "لا يوجد مستخدمين إلى الآن",
  "no roles": "لا يوجد وظائف حتى الآن",
  "no branch": "لا يوجد فروع إلى الآن",
  "no shifts": "لا يوجد شيفتات حتى الآن",
  "no logs": "لا يوجد تحركات حتى الآن",
  "nothing to show": "لا توجد معلومات للعرض",

  add: "أضِف مستخدم",
  edit: "تعديل المستخدم",
  clone: "إستنساخ المستخدم",
  save: "حفظ",
  cancel: "رجوع",
  activate: "تفعيل المستخدم",
  deactivate: "تعطيل المستخدم",
  "save & add": "حفظ وإضافة مستخدم",

  "required field": "هذا الحقل أساسي",
  "too short name": "الإسم قصير جدًا، يجب أن يكون 5 حروف على الأقل",
  "too long name": "الإسم طويل جدًا، يجب أن يكون 25 حرف على الأقل",
  "too short username": "الإسم قصير جدًا، يجب أن يكون 2 حروف على الأقل",
  "too long username": "الإسم طويل جدًا، يجب أن يكون 45 حرف على الأقل",
  "too short password": "يجب أن تكون كلمة المرور 6 حروف على الأقل",
  "invalid phone": "رقم التليفون غير صحيح",
  "invalid branch": "هذا الفرع غير صحيح",
  "invalid shift": "هذا الشيفت غير صحيح",
  "invalid role": "هذه الوظيفة غير صحيحة",
  "more than end": "أكبر من النهاية",
  "less than start": "أصغر من البداية",

  sun: "الأحد",
  mon: "الإثنين",
  tue: "الثلاثاء",
  wed: "الأربعاء",
  thu: "الخميس",
  fri: "الجمعة",
  sat: "السبت",

  CHECK_IN: "بداية اليوم",
  CHECK_OUT: "نهاية اليوم",
  IDLE: "متفرغ",
  ON_TRIP: "في رحلة",
  BREAK: "إستراحة",
};

export default ar;

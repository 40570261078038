const ar = {
  orders: "الطلبات",
  branch: "الفرع",
  captain: "الكابتن",
  date: "التاريخ",
  today: "اليوم",
  payment: "الدفع",
  reason: "السبب: ",
  EGP: "ج.م",
  SAR: "ر.س",
  type: "النوع",
  id: "المعرف",
  status: "الحالة",
  actions: "الخيارات",
  dispatch: "إرسال",
  dispatching: "جاري الإرسال",
  assign: "تكليف",
  idle: "متفرغ",
  max_mins_count: "{{count}} دقيقة كحد أقصى",
  mins_amount: "{{amount}}دق",
  mins_delay_amount: "{{amount}}دق تأخير",

  "todays data": "بيانات اليوم، يمكنك تغير التاريخ لبيانات أخرى",
  "delivered orders": "طلبات تم توصيلها",
  "returned orders": "طلبات تم إرتجاعها",
  "active captains": "كابتن نشط",
  "idle captains": "كابتن متفرغ",
  "change delay buffer": "تغيير سماحية التأخير",
  "delay buffer": "سماحية التأخير",
  "delivery captains": "كباتن التوصيل",
  "captain information": "بيانات الكابتن",
  "wallet custody": "عهدة المحفظة",
  "order cost": "تكلفة الطلب",
  "delivery cost": "تكلفة التوصيل",
  "return cost": "تكلفة الإرتجاع",
  "payment type": "طريقة الدفع",
  "packages no": "عدد الطرود",
  "order start": "بدء الطلب",
  "order delivery": "توصيل الطلب",
  "order pickup": "إستلام الطلب",
  "back to store": "العودة للفرع",
  "order cancelled": "تم إلغاء الطلب",
  "order rejected": "تم رفض الطلب",
  "by customer": "بواسطة العميل",
  "order number": "رقم الطلب",
  "start time": "توقيت البدء",
  "request time": "توقيت الطلب",
  "trip cost": "تكلفة الرحلة",
  "going/coming": "ذهاب | إياب",
  "add order title": "إضافة طلب توصيل جديد",
  "return order title": "إرتجاع طلب",
  "customer name": "إسم العميل",
  "customer phone": "رقم العميل",
  "customer address": "عنوان العميل",
  "customer notes": "ملاحظات العميل",
  "pick customer location": "اختر موقع العميل",
  "trip fees applied?": "تطبيق تكلفة الرحلة؟",
  "orders history": "الطلبات السابقة",
  "start date": "تاريخ البداية",
  "end date": "تاريخ النهاية",
  "order details": "تفاصيل الطلب",
  "not assigned": "لم يُكلَّف بها عامل",
  "idle captains only": "الكباتن المتفرغون فقط",
  "assign order to captain": "تكليف طلب لعامل",
  "please select captain": "من فضلك إختر الكابتن",
  "todays orders": "طلبات اليوم",

  "no orders": "لا يوجد طلبات حتى الآن",
  "no branch": "لا يوجد فروع إلى الآن",
  "no captains": "لا يوجد كباتن إلى الآن",
  "no idle captains": "لا يوجد كباتن متفرغون الآن",

  "invalid number": "هذا الرقم غير صحيح",
  "cannot exceed trip max duration": "لا يمكن تخطي مدة الرحلة الواحدة",
  "required field": "هذا الحقل أساسي",
  "too short name": "الإسم قصير جدًا، يجب أن يكون 2 حروف على الأقل",
  "too long name": "الإسم طويل جدًا، يجب أن يكون 50 حرف على الأقل",
  "too short address": "العنوان قصير جدًا، يجب أن يكون 10 حروف على الأقل",
  "too long address": "العنوان طويل جدًا، يجب أن يكون 100 حرف على الأقل",
  "too long note": "الملحوظة طويلة جدًا، يجب أن تكون 300 حرف على الأقل",
  "invalid branch": "هذا الفرع غير صحيح",
  "invalid captain": "هذا الكابتن غير صحيح",
  "invalid phone": "هذا الرقم غير صحيح",
  "invalid payment": "طريقة الدفع غير صحيحة",
  "position required": "الموقع مطلوب",
  "invalid date": "هذا التاريخ غير صحيح",
  "start date after end date": "تاريخ البدء بعد تاريخ الإنتهاء",
  "end date before start date": "تاريخ الإنتهاء قبل تاريخ البدء",
  "location outside area": "موقع العميل يجب أن يكون داخل مساحة تغطية الفرع",

  add: "أضِف طلب",
  save: "حفظ",
  clear: "إلغاء",
  "return order": "إرتجاع الطلب",
  "assign order": "تكليف الطلب",

  ACTIVE: "نشط",
  IDLE: "متفرغ",
  OFF_SHIFT: "خارج الشيفت",
  VACATION: "اجازة",
  BREAK: "إستراحة",
  MAX_WALLET: "المحفظة ممتلئة",

  DELIVERY: "توصيل",
  RETURN: "إرتجاع",

  NEW: "جديد",
  STARTED: "تم البدء",
  DELIVERED: "تم التوصيل",
  PICKED_UP: "تم الإلتقاط",
  CANCELLED: "تم الإلغاء",
  RETURNED: "تم الإرتجاع",

  CASH: "كاش",
  CARD: "بطاقة",
  PAID: "مدفوع",

  CAPTAIN: "بواسطة الكابتن",
  CUSTOMER: "بواسطة العميل",
  OPERATOR: "بواسطة الإدارة",
  UNKNOWN: "غير معروف",
  MAX_TIME_EXCEEDED: "لم يُكلَّف بها عامل",
};

export default ar;

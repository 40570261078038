const ar = {
  branches: "الفروع",
  branch: "الفرع",
  orders: "الطلبات",
  captains: "الكباتن",
  captain: "الكابتن",
  address: "العنوان",
  id: "المعرف",
  actions: "الخيارات",
  active: "نشط",
  captains_count: "{{count}} كابتن",
  orders_count: "{{count}} طلب",

  "search branches": "إبحث عن فرع",
  "branch name": "إسم الفرع",
  "branch image": "صورة الفرع",
  "branch address": "عنوان الفرع",
  "add branch title": "إضافة فرع جديد",
  "clone branch title": "إستنساخ فرع جديد",
  "edit branch title": "تعديل فرع",
  "branch location": "مكان الفرع",
  "sure to remove?": "هل أنت متأكد من إخراج الكابتن من الفرع؟",
  "choose location and area":
    "إختر موقع الفرع ومساحة التغطية الخاصة به على الخريطة",

  "no branches": "لا يوجد فروع حتى الآن",
  "no captains": "لا يوجد كباتن للفرع حتى الآن",
  "no other branches": "لا يوجد فروع أخرى",

  save: "حفظ",
  confirm: "تأكيد",
  "save & add": "حفظ وإضافة",
  "add branch": "أضِف فرع",
  "clone branch": "إستنسخ الفرع",
  "edit branch": "تعديل الفرع",
  "activate branch": "تفعيل الفرع",
  "deactivate branch": "تعطيل الفرع",
  "move to branch": "نقل إلى فرع",
  "remove from branch": "إخراج من الفرع",

  "required field": "هذا الحقل أساسي",
  "too short name": "الإسم قصير جدًا، يجب أن يكون 2 حروف على الأقل",
  "too long name": "الإسم طويل جدًا، يجب أن يكون 50 حرف على الأقل",
  "too short address": "العنوان قصير جدًا، يجب أن يكون 10 حروف على الأقل",
  "too long address": "العنوان طويل جدًا، يجب أن يكون 100 حرف على الأقل",
  "invalid branch": "هذا الفرع غير صحيح",
  "position required": "موقع الفرع مطلوب",
  "area required": "مساحة التغطية مطلوبة",
  "invalid area": "مساحة التغطية غير صحيحة",
  "location outside area": "الموقع يجب أن يكون داخل مساحة تغطية الفرع",
};

export default ar;

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";

const useStyles = makeStyles((theme) => ({
  container: ({ color }) => ({
    display: "flex",
    alignItems: "center",
    color:
      color === "primary"
        ? theme.palette.primary.main
        : color === "secondary"
        ? theme.palette.secondary.main
        : color === "textPrimary"
        ? theme.palette.text.primary
        : color === "textSecondary"
        ? theme.palette.text.secondary
        : color === "error"
        ? theme.palette.error.main
        : color === "success"
        ? theme.palette.success.main
        : "inherit",
  }),
  icon: ({ iconColor }) => ({
    marginRight: 6,
    color:
      iconColor === "primary"
        ? theme.palette.primary.main
        : iconColor === "secondary"
        ? theme.palette.secondary.main
        : iconColor === "error"
        ? theme.palette.error.main
        : iconColor === "success"
        ? theme.palette.success.main
        : iconColor === "action"
        ? theme.palette.action.active
        : "inherit",
  }),
}));

function IconText({ text, variant, color, icon, iconSize, iconColor }) {
  const classes = useStyles({ color, iconColor });

  return (
    <Typography
      component="span"
      variant={variant || "body2"}
      className={classes.container}
    >
      <SvgIcon fontSize={iconSize || "inherit"} className={classes.icon}>
        <path d={icon} />
      </SvgIcon>

      <Typography component="span" variant="inherit" noWrap>
        {text}
      </Typography>
    </Typography>
  );
}

IconText.propTypes = {
  text: PropTypes.node,
  // Typography variant
  variant: PropTypes.oneOf([
    "body1",
    "body2",
    "subtitle1",
    "subtitle2",
    "caption",
  ]),
  // Typography color
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "textPrimary",
    "textSecondary",
    "error",
    "success",
  ]),

  // icon SVG string path
  icon: PropTypes.string.isRequired,
  // icon size
  iconSize: PropTypes.oneOf(["small", "medium", "large"]),
  // icon color
  iconColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "error",
    "success",
    "action",
  ]),
};

export default IconText;

const ar = {
  wallets: "المحافظ",
  type: "النوع",
  amount: "المبلغ",
  order: "الطلب",
  date: "التاريخ",
  EGP: "ج.م",
  SAR: "ر.س",
  total: "الإجمالي",

  "wallet custody": "عهدة المحفظة",
  "order number": "رقم الطلب",
  "delivery cost": "تكلفة التوصيل",
  "return cost": "تكلفة الإرتجاع",
  "required amount": "المبلغ المطلوب",

  "no workers": "لا يوجد كباتن لديهم عهدة بالمحفظة",
  "no custody": "لا يوجد عهدة ماليات لدى الكباتن حتى الآن",
  "no custody for worker": "لا يوجد عهدة حتى الآن لدى {{name}}",

  confirm: "تأكيد",
  search: "بحث",
  "add custody": "إضافة عهدة",
  "return all": "سحب الكل",

  "confirm custody return": "تأكيد سحب كل العهدة",
  "confirm custody return message":
    "هل ترغب في سحب جميع عهدة الماليات من الكابتن",

  "required field": "هذا الحقل مطلوب",
  "max 36 characters": "٣٦ حرف على الأكثر",
  "invalid order": "الطلب غير صحيح",
  "return orders allowed": "مسموح فقط لطلبات الإرتجاع",

  DELIVERY_ORDER: "طلب توصيل",
  CUSTODY_ADD: "إضافة عهدة",
};

export default ar;

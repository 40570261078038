const ar = {
  owner: "المالك",
  edit: "تعديل",
  cancel: "رجوع",
  save: "حفظ",
  email: "البريد الإلكتروني",
  phone: "الهاتف",
  password: "كلمة المرور",
  "my profile": "حسابي الشخصي",
  "edit profile": "تعديل الحساب",
  "profile info": "بيانات الحساب",
  "full name": "الإسم كامل",
  "user image": "صورة المستخدم",
  "change password": "تغيير كلمة المرور",
  "new password": "كلمة المرور الجديدة",
  "confirm password": "تأكيد كلمة المرور",
  "old password": "كلمة المرور السابقة",
  "required field": "هذا الحقل أساسي",
  "too short name": "الإسم قصير جدًا، يجب أن يكون 5 حروف على الأقل",
  "too long name": "الإسم طويل جدًا، يجب أن يكون 25 حرف على الأقل",
  "invalid email": "البريد الإلكتروني غير صحيح",
  "invalid phone": "رقم التليفون غير صحيح",
  "two passwords not matching": "كلمتا المرور الجديدتان غير متطابقتان",
  "new password matches old passowrd":
    "كلمة المرور الجديدة يجب ألا تطابق كلمة المرور السابقة",
  "old password is wrong": "كلمة المرور السابقة غير صحيحة",
  "required six characters": "يجب أن تكون كلمة المرور 6 حروف على الأقل",
  "password hint":
    "لكلمة مرور قوية إستخدم: \n- الأرقام \n- الحروف الصغيرة والكبيرة والمميزة",
};

export default ar;

import { SET_FILTERS } from "../actions/actionTypes";

const INIT_STATE = {
  orderTypeFilters: [],
  orderStatusFilters: [],
  captainStatusFilters: [],
};

const filtersReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_FILTERS:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default filtersReducer;

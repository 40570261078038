import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from "../actions/actionTypes";

const INIT_STATE = {
  message: "",
  variant: "", // success || warning || error
};

const notificationReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SHOW_NOTIFICATION:
      return payload;

    case HIDE_NOTIFICATION:
      return INIT_STATE;

    default:
      return state;
  }
};

export default notificationReducer;

const en = {
  heading:
    "Ultra fast delivery management, choose how you want to create your on-demand business.",
  subheading:
    "It's time to develop your business. Grow up, save more and earn more.",
  store: "Store",
  restaurant: "Restaurant",
  phone: "Phone",
  currency: "Currency",
  email: "Email",
  password: "Password",

  adv_1: "Save 48% on delivery costs,",
  adv_2: "Reduce time spent on delivery by 83%,",
  adv_3: "Run fleet reports 10x faster than before.",

  "have questions": "Have questions?",
  "call us": "Call us on",
  "or email us": "or email us at",
  "start your 30 days": "Start your 30-days",
  "free trial": "free trial.",
  "company info": "Company Information",
  "owner info": "Owner Information",
  "company name": "Company Name",
  "owner name": "Owner Name",
  "cloud kitchen": "Cloud Kitchen",
  "dark store": "Dark Store",
  "verify email": "Verify E-mail",
  "email sent": "We have sent you a confirmation message at",
  "email resent": "New confirmation message have just been resent at",
  "verification code": "Verification Code",
  "enter email and code": "Enter your email and verification code to verify.",
  "already registered": "Already registered before? Verify",
  "have an account": "Have an account? Login",
  "didnt register": "Didn't register? Free signup",

  verify: "Verify",
  resend: "Resend",
  "start trial": "Start 30-days Trial",
  "resend after": "Resend after",

  "required field": "This field is required",
  "too short name": "Too short name, should be 5 letters at least",
  "too long name": "Too long name, should be 25 letters at most",
  "invalid phone": "Invalid phone number",
  "invalid email": "Invalid email",
  "too short password": "Password should be 6 characters at least",
  "invalid code": "Code must be exact 6 digits",
};

export default en;

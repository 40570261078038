import axios from "axios";
import config from "../config.json";

const setToken = Symbol("setToken");
const removeToken = Symbol("removeToken");
const buildURL = Symbol("buildURL");

class HttpClient {
  /**
   * @constructor
   * @param {Object} extraHeaders
   */
  constructor(extraHeaders = {}) {
    this.client = axios.create({
      baseURL: config.url,
      headers: {
        ...extraHeaders,
      },
    });
  }

  /**
   * Private Method - Set Client token Header
   * @param {string} token
   */
  [setToken](token) {
    this.client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  /**
   * Private Method - Remove Client token Header
   */
  [removeToken]() {
    delete this.client.defaults.headers.common["Authorization"];
  }

  /**
   * Private Method - Build URL with Query String
   * @param {string} url
   * @param {Object} queryParams
   */
  [buildURL](url, queryParams) {
    let urlParams = new URLSearchParams();

    for (const key in queryParams) {
      if (queryParams[key] && typeof queryParams[key] !== "object") {
        urlParams.append(key, queryParams[key]);
      } else if (
        queryParams[key] instanceof Array &&
        queryParams[key].length > 0
      ) {
        urlParams.append(key, queryParams[key].join(","));
      }
    }
    return `${url}?${urlParams.toString()}`;
  }

  /**
   * HTTP GET Method
   * @async
   * @param {string} token
   * @param {string} url
   * @param {Object=} queryParams
   * @param {Object=} config
   */
  async get(token, url, queryParams, config = {}) {
    this[setToken](token);
    url = queryParams ? this[buildURL](url, queryParams) : url;

    try {
      const res = await this.client.get(url, config);
      return res.data;
    } catch (error) {
      return error.response?.data || { network_error: [error.message] };
    }
  }

  /**
   * HTTP GET Method - Multiple resources
   * @async
   * @param {string} token
   * @param {string []} urls
   */
  async getParallel(token, ...urls) {
    this[setToken](token);

    return await Promise.all(
      urls.map((url) =>
        this.client
          .get(url)
          .then((res) => res.data)
          .catch((err) => err.response.data)
      )
    );
  }

  /**
   * HTTP DELETE Method
   * @async
   * @param {string} token
   * @param {string} url
   */
  async delete(token, url) {
    this[setToken](token);

    try {
      const res = await this.client.delete(url);
      return res.data;
    } catch (error) {
      return error.response?.data || { network_error: [error.message] };
    }
  }

  /**
   * HTTP POST Method
   * @async
   * @param {string} token
   * @param {string} url
   * @param {Object} data
   * @param {Object=} config
   */
  async post(token, url, data, config = {}) {
    token ? this[setToken](token) : this[removeToken]();

    try {
      const res = await this.client.post(url, data, config);
      return res.data;
    } catch (error) {
      return error.response?.data || { network_error: [error.message] };
    }
  }

  /**
   * HTTP PUT Method
   * @async
   * @param {string} token
   * @param {string} url
   * @param {Object} data
   */
  async put(token, url, data) {
    this[setToken](token);

    try {
      const res = await this.client.put(url, data);
      return res.data;
    } catch (error) {
      return error.response?.data || { network_error: [error.message] };
    }
  }

  /**
   * HTTP PATCH Method
   * @async
   * @param {string} token
   * @param {string} url
   * @param {Object} data
   */
  async patch(token, url, data) {
    this[setToken](token);

    try {
      const res = await this.client.patch(url, data);
      return res.data;
    } catch (error) {
      return error.response?.data || { network_error: [error.message] };
    }
  }
}

export default HttpClient;

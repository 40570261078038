import { combineReducers } from "redux";

import toolsPage from "./toolsPage";
import singleTool from "./singleTool";
import toolsHistoryPage from "./toolsHistoryPage";

import shelvesPage from "./shelvesPage";
import singleShelf from "./singleShelf";

import workersPage from "./workersPage";

export default combineReducers({
  toolsPage,
  singleTool,
  toolsHistoryPage,

  shelvesPage,
  singleShelf,

  workersPage,
});

import { apiLogout } from "../../apis/SharedClient";

import createApiAction from "./base/createApiAction";
import { SHOW_NOTIFICATION, USER_LOGGEDOUT } from "./actionTypes";

/**
 * Async action creator to logout a user
 */
const logout = () => (dispatch, getState) => {
  const executeRequest = async (token) => {
    return apiLogout(token);
  };

  const handleResponse = ({ status_code }) => {
    switch (status_code) {
      case 200:
        dispatch({ type: USER_LOGGEDOUT });
        break;

      default:
        dispatch({
          type: SHOW_NOTIFICATION,
          message: { variant: "error", message: "something went wrong" },
        });
        break;
    }
  };

  createApiAction(dispatch, getState, executeRequest, handleResponse);
};

export default logout;

import fallback from "./fallback";
import feedback from "./feedback";

import app from "../modules/App/locales";
import auth from "../modules/Auth/locales";
import onboarding from "../modules/Onboarding/locales";

import profiles from "../modules/Profiles/locales";
import dashboard from "../modules/Dashboard/locales";
import orders from "../modules/Orders/locales";
import branches from "../modules/Branches/locales";
import warehouse from "../modules/Warehouse/locales";
import settings from "../modules/Settings/locales";
import wallets from "../modules/Wallets/locales";
import users from "../modules/Users/locales";
import reports from "../modules/Reports/locales";

const appLocales = {
  ar: {
    fallback: fallback.ar,
    feedback: feedback.ar,

    app: app.ar,
    auth: auth.ar,
    onboarding: onboarding.ar,

    profiles: profiles.ar,
    dashboard: dashboard.ar,
    orders: orders.ar,
    branches: branches.ar,
    warehouse: warehouse.ar,
    wallets: wallets.ar,
    users: users.ar,
    settings: settings.ar,
    reports: reports.ar,
  },
  en: {
    fallback: fallback.en,
    feedback: feedback.en,

    app: app.en,
    auth: auth.en,
    onboarding: onboarding.en,

    profiles: profiles.en,
    dashboard: dashboard.en,
    orders: orders.en,
    branches: branches.en,
    warehouse: warehouse.en,
    wallets: wallets.en,
    users: users.en,
    settings: settings.en,
    reports: reports.en,
  },
};

export default appLocales;

import { MODULE_ICONS } from "../../../constants/icons";

/**
 * Creates navigation lists object
 * @param {Object} args - args
 * @param {Object} args.modules - active modules object
 *
 * @returns {Object} navigation object primary/secondary
 */
function makeNavList({ modules }) {
  return {
    primary: [
      {
        navName: "dashboard",
        navLink: "/dashboard",
        icon: MODULE_ICONS["dashboard"],
        active: modules.dashboard,
      },
      {
        navName: "orders",
        navLink: "/orders",
        icon: MODULE_ICONS["orders"],
        active: modules.orders,
        subNavs: [
          {
            navName: "all orders",
            navLink: "/orders",
            active: modules.orders,
          },
          {
            navName: "orders tracking",
            navLink: "/orders/tracking",
            active: modules.orders,
          },
        ],
      },
      {
        navName: "team",
        navLink: "/users",
        icon: MODULE_ICONS["team"],
        active: modules.team,
      },
      {
        navName: "branches",
        navLink: "/branches",
        icon: MODULE_ICONS["branches"],
        active: modules.branches,
      },
      {
        navName: "warehouse",
        navLink: "/warehouse",
        icon: MODULE_ICONS["warehouse"],
        active: Object.values(modules.warehouse).some(Boolean),
        subNavs: [
          {
            navName: "tools",
            navLink: "/warehouse/tools",
            active: modules.warehouse.tools,
          },
          {
            navName: "shelves",
            navLink: "/warehouse/shelves",
            active: modules.warehouse.shelves,
          },
        ],
      },
      {
        navName: "wallets",
        navLink: "/wallets",
        icon: MODULE_ICONS["wallets"],
        active: modules.wallets,
      },
      // {
      //   navName: "reports",
      //   navLink: "/reports",
      //   icon: MODULE_ICONS["reports"],
      //   active: modules.reports,
      // },
    ],
    secondary: [
      {
        navName: "settings",
        navLink: "/settings",
        icon: MODULE_ICONS["settings"],
        active: Object.values(modules.settings).some(Boolean),
        subNavs: [
          {
            navName: "system settings",
            navLink: "/settings",
            active: modules.settings.system,
          },
          {
            navName: "permissions",
            navLink: "/settings/roles",
            active: modules.settings.permissions,
          },
        ],
      },
      {
        navName: "help",
        navLink: "/help",
        icon: MODULE_ICONS["help"],
        active: false,
      },
    ],
  };
}

export default makeNavList;

import { TOOLS_PAGE_FETCHED, TOOL_UPDATED } from "../actions/actionTypes";
import { TOOL_CUSTODY_MOVED } from "../../../../redux/shared/actions/actionTypes";

const INITIAL_STATE = { metadata: {}, data: [], filters: {} };

const toolsPageReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOOLS_PAGE_FETCHED:
      return payload;

    case TOOL_UPDATED:
      return {
        ...state,
        data: state.data.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };

    case TOOL_CUSTODY_MOVED:
      return {
        ...state,
        data: state.data.map((item) =>
          item.id === payload.toolID && payload.worker // moved to worker
            ? { ...item, custody: payload.worker }
            : item.id === payload.toolID && payload.shelf // moved to shelf
            ? { ...item, shelf: payload.shelf, custody: null }
            : item.id === payload.toolID
            ? { ...item, custody: null } // moved to warehouse
            : item
        ),
      };

    default:
      return state;
  }
};

export default toolsPageReducer;

import {
  REQUEST_START,
  REQUEST_END,
  SHOW_NOTIFICATION,
  USER_LOGGEDOUT,
  NOT_FOUND,
  ACCESS_DENIED,
} from "../actionTypes";

/**
 * callback that initiates a network request
 * @callback executorCb
 * @param {string} token - auth access token
 */
/**
 * callback that handle the response
 * @callback onResponseCb
 * @param {Object} res - the api response
 */
/**
 * Async action creator that requests an api through network
 * @param {Function} dispatch - redux store dispatch
 * @param {Function} getState - redux store getState
 * @param {executorCb} executor - a function that starts a network call
 * @param {onResponseCb} onResponse - a function that handles response
 */
async function createApiAction(dispatch, getState, executor, onResponse) {
  const {
    auth: { access_token },
  } = getState();

  dispatch({ type: REQUEST_START });

  const response = await executor(access_token);

  dispatch({ type: REQUEST_END });

  const { status_code, errors, network_error } = response;

  // if a network error ocurred, inform the user
  if (network_error) {
    dispatch({
      type: SHOW_NOTIFICATION,
      payload: { variant: "error", message: "network error" },
    });

    return;
  }

  // if user is unauthorized, logout that user from the app
  if (status_code === 401) {
    dispatch({ type: USER_LOGGEDOUT });
    return;
  }

  // if resource is forbidden, inform the user
  if (status_code === 403) {
    dispatch({ type: ACCESS_DENIED });
    return;
  }

  // if resource is not found, inform the user
  if (status_code === 404) {
    dispatch({ type: NOT_FOUND });
    return;
  }

  // if response has a generic error and logged user exists, display that error
  if (status_code === 400 && access_token && errors.generic) {
    dispatch({
      type: SHOW_NOTIFICATION,
      payload: { variant: "error", message: errors.generic },
    });
  }

  // handle response
  onResponse(response);
}

export default createApiAction;

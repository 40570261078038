import { useState, useEffect, useMemo, Fragment } from "react";
import PropTypes from "prop-types";

import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { makeStyles, alpha } from "@material-ui/core/styles";
import clsx from "clsx";

import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SvgIcon from "@material-ui/core/SvgIcon";

import makeNavList from "../../helpers/makeNavList";

import logo_en from "../../../../assets/logos/md_logo_en.svg";
import logo_ar from "../../../../assets/logos/md_logo_ar.svg";

const useSytyle = makeStyles((theme) => ({
  nav: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    paddingTop: theme.spacing(11),
  },
  navListSecondary: {
    marginTop: 20,
  },
  poweredBy: {
    padding: theme.spacing(2.25),
    marginTop: "auto",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  logoImg: {
    width: 140,
  },
  logoTextLarge: {
    fontSize: theme.typography.pxToRem(16),
  },
  logoTextSmall: {
    fontSize: theme.typography.pxToRem(12),
  },
  listItem: {
    paddingLeft: theme.spacing(3),
    transition: theme.transitions.create("color", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
    "&:hover": {
      backgroundColor: "initial",
    },
  },
  listItemPrimary: {
    color: theme.palette.primary.main,
  },
  listItemSecondary: {
    color: theme.palette.blueGrey[600],
  },
  listItemActive: {
    color: theme.palette.secondary.main,
    paddingTop: 0,
    paddingBottom: 0,
  },
  nestedListItem: {
    position: "relative",
    marginLeft: theme.spacing(7.5),
    transition: theme.transitions.create("color", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
    "&:hover": {
      backgroundColor: "initial",
    },
  },
  nestedListItemPrimary: {
    color: theme.palette.primary.main,
  },
  nestedListItemSecondary: {
    color: theme.palette.blueGrey[600],
  },
  nestedListItemActive: {
    color: theme.palette.secondary.main,
    "&::before": {
      content: '""',
      position: "absolute",
      width: 2,
      height: "100%",
      left: -2,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  listItemIcon: {
    color: "inherit",
  },
  listItemIconActive: {
    padding: theme.spacing(0, 1.25, 1, 1.25),
    paddingTop: theme.spacing(1.25) + 1,
    marginLeft: theme.spacing(-1.25),
    borderRadius: 5,
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  },
}));

function SideMenu({ openDrawer, isDrawerOpen }) {
  const classes = useSytyle();
  const { t, i18n } = useTranslation("app");

  const { pathname } = useLocation();
  const history = useHistory();

  const modules = useSelector((state) => state.auth.modules);
  const navigationList = useMemo(() => makeNavList({ modules }), [modules]);

  // navigate to default route
  useEffect(() => {
    if (pathname !== "/") return;

    const firstActiveNav = navigationList.primary.find((i) => i.active);
    const defaultNavLink = !!firstActiveNav.subNavs
      ? firstActiveNav.subNavs.find((i) => i.active).navLink
      : firstActiveNav.navLink;

    history.replace(defaultNavLink);
  }, [pathname, history, navigationList]);

  // state === nav item name that its dropdown is currentlty opened
  const [openedDropdown, setOpenedDropdown] = useState("");
  // if the drawer is closed, close dropdowns
  useEffect(() => {
    !isDrawerOpen && setOpenedDropdown("");
  }, [isDrawerOpen]);

  // nav item click handler
  const handleItemClick = (navName) => {
    // if opened dropdown is the same, then close it. if not, then close all and open it
    setOpenedDropdown(navName === openedDropdown ? "" : navName);
    // if drawer is closed, open it
    !isDrawerOpen && openDrawer();
  };

  // ----- render helpers
  const renderNavList = (navList, isPrimary) => {
    return navList.map((navItem) => (
      <Fragment key={navItem.navName}>
        {navItem.active && renderNavItem(navItem, isPrimary)}

        {navItem.active && navItem.subNavs ? (
          <Collapse
            in={navItem.navName === openedDropdown}
            timeout="auto"
            unmountOnExit
          >
            <List component="ul" dense>
              {navItem.subNavs.map(
                (subNavItem) =>
                  subNavItem.active && renderSubNavItem(subNavItem, isPrimary)
              )}
            </List>
          </Collapse>
        ) : null}
      </Fragment>
    ));
  };

  const renderExpansionIcon = (navName) => {
    return navName === openedDropdown ? (
      <ExpandLess fontSize="small" />
    ) : (
      <ExpandMore fontSize="small" />
    );
  };

  const renderNavItem = ({ navName, navLink, subNavs, icon }, isPrimary) => {
    const isActive =
      navLink === pathname || navLink === pathname.split("/", 2).join("/");
    return (
      <li key={navName}>
        <ListItem
          button
          component={!subNavs ? RouterLink : "div"}
          to={!subNavs ? navLink : null}
          className={clsx(classes.listItem, {
            [classes.listItemPrimary]: isPrimary,
            [classes.listItemSecondary]: !isPrimary,
            [classes.listItemActive]: isActive,
          })}
          onClick={() => !!subNavs && handleItemClick(navName)}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <div
              className={clsx({
                [classes.listItemIconActive]: isActive,
              })}
            >
              <SvgIcon>
                <path d={icon} />
              </SvgIcon>
            </div>
          </ListItemIcon>

          <ListItemText primary={t(navName)} />

          {subNavs ? renderExpansionIcon(navName) : null}
        </ListItem>
      </li>
    );
  };

  const renderSubNavItem = ({ navName, navLink }, isPrimary) => (
    <li key={navName}>
      <ListItem
        button
        component={RouterLink}
        to={navLink}
        className={clsx(classes.nestedListItem, {
          [classes.nestedListItemPrimary]: isPrimary,
          [classes.nestedListItemSecondary]: !isPrimary,
          [classes.nestedListItemActive]: navLink === pathname,
        })}
      >
        <ListItemText primary={t(navName)} />
      </ListItem>
    </li>
  );

  return (
    <nav className={classes.nav}>
      <List component="ul" disablePadding>
        {renderNavList(navigationList.primary, true)}
      </List>

      <List component="ul" disablePadding className={classes.navListSecondary}>
        {renderNavList(navigationList.secondary)}
      </List>

      <div className={classes.poweredBy}>
        {isDrawerOpen && (
          <Typography component="span" variant="caption" gutterBottom>
            {t("powered by")}
          </Typography>
        )}

        <div className={classes.logoContainer}>
          <img
            alt="logo"
            src={i18n.language === "en" ? logo_en : logo_ar}
            className={classes.logoImg}
          />

          {/* {isDrawerOpen && (
            <Typography
              component="span"
              color="primary"
              className={classes.logoTextLarge}
            >
              {t("qafeer")}
              <Typography
                component="span"
                display="block"
                className={classes.logoTextSmall}
              >
                {t("workers management")}
              </Typography>
            </Typography>
          )} */}
        </div>
      </div>
    </nav>
  );
}

SideMenu.propTypes = {
  openDrawer: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
};

export default SideMenu;

import {
  TOOL_CUSTODIES_PAGE_FETCHED,
  TOOL_CUSTODY_MOVED,
} from "../actions/actionTypes";

const INITIAL_STATE = { metadata: {}, data: [], filters: {} };

const toolCustodiesPageReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOOL_CUSTODIES_PAGE_FETCHED:
      return payload;

    case TOOL_CUSTODY_MOVED:
      return {
        ...state,
        data: state.data.filter((item) => item.tool.id !== payload.toolID),
      };

    default:
      return state;
  }
};

export default toolCustodiesPageReducer;

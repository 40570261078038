import { combineReducers } from "redux";

import shifts from "./shifts";
import singleShift from "./singleShift";

import rolesPage from "./rolesPage";
import singleRole from "./singleRole";
import defaultRole from "./defaultRole";
import allRoleUsers from "./allRoleUsers";
import allUsersWithoutRole from "./allUsersWithoutRole";

export default combineReducers({
  shifts,
  singleShift,

  rolesPage,
  singleRole,
  defaultRole,
  allRoleUsers,
  allUsersWithoutRole,
});

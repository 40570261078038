import { combineReducers } from "redux";

import appRequests from "./appRequests";
import appRequestStatus from "./appRequestStatus";
import notification from "./notification";

import settings from "./settings";

import ordersPage from "./ordersPage";
import toolCustodiesPage from "./toolCustodiesPage";
import walletCustodiesPage from "./walletCustodiesPage";

import allUsers from "./allUsers";
import allBranches from "./allBranches";
import allRoles from "./allRoles";
import allShifts from "./allShifts";
import allShelves from "./allShelves";

export default combineReducers({
  appRequests,
  appRequestStatus,
  notification,

  settings,

  ordersPage,
  walletCustodiesPage,
  toolCustodiesPage,

  allUsers,
  allBranches,
  allRoles,
  allShifts,
  allShelves,
});

/** TOOLS SECTION **/
export const TOOLS_PAGE_FETCHED = "WAR_TOOLS_PAGE_FETCHED";
export const TOOL_FETCHED = "WAR_TOOL_FETCHED";
export const TOOL_ADDED = "WAR_TOOL_ADDED";
export const TOOL_UPDATED = "WAR_TOOL_UPDATED";
export const TOOLS_HISTORY_PAGE_FETCHED = "WAR_TOOLS_HISTORY_PAGE_FETCHED";

/** SHELVES SECTION **/
export const SHELVES_PAGE_FETCHED = "WAR_SHELVES_PAGE_FETCHED";
export const SHELF_FETCHED = "WAR_SHELF_FETCHED";
export const SHELF_ADDED = "WAR_SHELF_ADDED";
export const SHELF_UPDATED = "WAR_SHELF_UPDATED";

/** USERS SECTON **/
export const USERS_PAGE_FETCHED = "WAR_USERS_PAGE_FETCHED";

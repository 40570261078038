const en = {
  qafeer: "Qafeer",
  logout: "Logout",
  profile: "Profile",

  dashboard: "Dashboard",
  orders: "Orders",
  "orders tracking": "Orders Tracking",
  "all orders": "All Orders",
  branches: "Branches",
  warehouse: "Warehouse",
  tools: "Tools",
  shelves: "Shelves",
  team: "Team",
  reports: "Reports",
  wallets: "Wallets",
  settings: "Settings",
  permissions: "Permissions",
  help: "Help",

  "system settings": "System Settings",
  "powered by": "Powered by",
  "workers management": "Workers Management",

  "welcome heading":
    "Welcome to fast delivery, you have just started your 30-days free trial!",
  "welcome subheading": "Please complete you company and system setup.",
  "system setup": "System Setup",
  "number of branches": "Number of Branches",
  "number of captains": "Number of Captains",
  "on trial plan": "Your account is on 30-days free trial plan which ends on:",
  "trial ended": "Your free trial has ended!",
  "plan ended": "Your plan has ended!",
  "go to subscriptions":
    "Go to subscriptions and chosse 'Purchase Plan' to continue using Fast Delivery",

  save: "Save",
  subscriptions: "Subscriptions",
  "start premium": "Start Premium",

  "required field": "This field is required",
  "must be more than one": "Must be more than zero",
};

export default en;

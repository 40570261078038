const en = {
  user: "User",
  username: "Username",
  users: "Users",
  email: "Email",
  phone: "Phone",
  password: "Password",
  role: "Role",
  branch: "Branch",
  shift: "Shift",
  ranking: "Ranking",
  orders: "Orders",
  tools: "Tools",
  wallet: "Wallet",
  actions: "Actions",
  action: "Action",
  from: "From",
  to: "To",
  duration: "Duration",
  active: "Active",
  search: "Search Users",
  EGP: "EGP",
  SAR: "SAR",
  km_amount: "{{amount}} KM",
  mins_amount: "{{amount}}mins",

  "activity log": "Activity Log",
  "add user": "Add New User",
  "edit user": "Edit User",
  "clone user": "Clone New User",
  "full name": "Full Name",
  "user details": "User Details",
  "user role": "User Role",
  "user image": "User Image",
  "delivery orders": "Delivery Orders",
  "return orders": "Return Orders",
  "orders from": "Orders From",
  "orders to": "Orders To",
  "off day": "Off Day",

  "no users": "There's no users yet",
  "no roles": "There's no roles yet",
  "no branches": "There's no branches yet",
  "no shifts": "There's no shifts yet",
  "no logs": "There's no logs yet",
  "nothing to show": "No more information to show",

  add: "Add User",
  edit: "Edit User",
  clone: "Clone User",
  save: "Save",
  cancel: "Cancel",
  activate: "Activate User",
  deactivate: "Deactivate User",
  "save & add": "Save & Add",

  "required field": "This field is required",
  "too short name": "Too short name, should be 5 letters at least",
  "too long name": "Too long name, should be 25 letters at most",
  "too short username": "Too short name, should be 2 letters at least",
  "too long username": "Too long name, should be 45 letters at most",
  "too short password": "Password should be 6 characters at least",
  "invalid phone": "Invalid phone number",
  "invalid branch": "Invalid branch",
  "invalid shift": "Invalid shift",
  "invalid role": "Invalid role",
  "more than end": "More than end",
  "less than start": "Less than start",

  sun: "Sunday",
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",

  CHECK_IN: "Check In",
  CHECK_OUT: "Check Out",
  IDLE: "Idle",
  ON_TRIP: "On Trip",
  BREAK: "Break",
};

export default en;

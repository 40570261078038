export const ORDERS_DATA_FETCHED = "ORD_ORDERS_DATA_FETCHED";
export const MAP_DATA_FETCHED = "ORD_MAP_DATA_FETCHED";

export const SET_NOW = "ORD_SET_NOW";
export const SET_DELAY_BUFFER = "ORD_SET_DELAY_BUFFER";
export const SET_FILTERS = "ORD_SET_FILTERS";

export const RT_ORDER_ASSIGNED = "ORD_RT_ORDER_ASSIGNED";
export const RT_ORDER_UPDATED = "ORD_RT_ORDER_UPDATED";
export const RT_CAPTAIN_UPDATED = "ORD_RT_CAPTAIN_UPDATED";
export const RT_STATS_UPDATED = "ORD_RT_STATS_UPDATED";
export const RT_TRIP_UPDATED = "ORD_RT_TRIP_UPDATED";

export const ORDER_FETCHED = "ORD_ORDER_FETCHED";
export const ORDER_ADDED = "ORD_ORDER_ADDED";

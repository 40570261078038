const en = {
  username: "Username",
  email: "Email",
  login: "Login",
  password: "Password",
  code: "Verification Code",
  verify: "Verify",
  save: "Save",
  or: "Or",
  signup: "Free trial signup",
  "change password": "Change Password",
  "keep me logged in": "Keep me logged in",
  "new password": "New Password",
  "confirm password": "Confirm Password",
  "forgot password": "Forgot Password?",
  "password recovery": "Password Recovery",
  "we will send a verification code": "We will send you a verification code",
  "please enter received code":
    "Please enter the verification code you received",
  "request code": "Request Code",
  "having code already?": "Having verification code already?",
  "verify code": "Verify Code",
  "don't have code?": "Don't have verification code?",
  "two passwords not matching": "The new passwords don't match",
  "required field": "This field is required",
  "required six digits": "Code must be exact 6 digits",
  "required six characters": "Password should be 6 characters at least",
  "invalid username": "Invalid username",
  "invalid username or password":
    "Username or Password is wrong, please check and try again",
  "invalid username or email":
    "Username or Email is wrong, please check and try again",
  "password hint":
    "For strong password use:\n- Special characters\n- Numbers, Capital letters, and Small letters",
  "password changed":
    "Password changed successfully, please login with the new one",
  "invalid verification code":
    "The verification code you entered is wrong or expired, please try again",
};

export default en;

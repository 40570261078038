import { TOOL_FETCHED, TOOL_UPDATED } from "../actions/actionTypes";
import { TOOL_CUSTODY_MOVED } from "../../../../redux/shared/actions/actionTypes";

const singleToolReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOOL_FETCHED:
      return payload;

    case TOOL_UPDATED:
      return state.id === payload.id ? { ...state, ...payload } : state;

    case TOOL_CUSTODY_MOVED:
      return state.id === payload.toolID && payload.worker // moved to worker
        ? { ...state, custody: payload.worker }
        : state.id === payload.toolID && payload.shelf // moved to worker
        ? { ...state, shelf: payload.shelf, custody: null }
        : state.id === payload.toolID
        ? { ...state, custody: null } // moved to warehouse
        : state;

    default:
      return state;
  }
};

export default singleToolReducer;

import { REQUEST_START, REQUEST_END } from "../actions/actionTypes";

const INIT_STATE = {
  count: 0,
  active: false,
};

const appRequestsReducer = (state = INIT_STATE, action) => {
  const { type } = action;

  switch (type) {
    case REQUEST_START:
      return {
        count: state.count + 1,
        active: true,
      };

    case REQUEST_END:
      return {
        count: state.count - 1,
        active: state.count - 1 > 0,
      };

    default:
      return state;
  }
};

export default appRequestsReducer;

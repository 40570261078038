import { format } from "date-fns";
import enLocale from "date-fns/locale/en-US";
import arLocale from "date-fns/locale/ar-SA";

export const DATEFNS_LOCALE = { en: enLocale, ar: arLocale };

export const DATE_FORMAT = {
  en: "eee, dd MMMM yyyy",
  ar: "eeee، dd MMMM yyyy",
};

export const DATE_FORMAT_SHORT = {
  en: "dd MMM yyyy",
  ar: "dd MMM yyyy",
};

export const DATE_TIME_FORMAT = {
  en: "dd MMMM yyyy h:mma",
  ar: "dd MMMM yyyy h:mma",
};

export const TIME_FORMAT = { en: "hh:mma", ar: "hh:mma" };

export const formatDate = (date, locale) => {
  if (!date) return "";

  return format(new Date(date), DATE_FORMAT[locale], {
    locale: DATEFNS_LOCALE[locale],
  });
};

export const formatDateShort = (date, locale) => {
  if (!date) return "";

  return format(new Date(date), DATE_FORMAT_SHORT[locale], {
    locale: DATEFNS_LOCALE[locale],
  });
};

export const formatTime = (date, locale) => {
  if (!date) return "";

  return format(new Date(date), TIME_FORMAT[locale], {
    locale: DATEFNS_LOCALE[locale],
  });
};

export const formatDateTime = (date, locale) => {
  if (!date) return "";

  return format(new Date(date), DATE_TIME_FORMAT[locale], {
    locale: DATEFNS_LOCALE[locale],
  });
};

import { apiPatchUser } from "../../apis/SharedClient";

import createApiAction from "./base/createApiAction";
import { PROFILE_INFO_UPDATED, SHOW_NOTIFICATION } from "./actionTypes";

/**
 * callback to handle request success
 * @callback successCb
 */
/**
 * callback to handle request success
 * @callback failureCb
 * @param {Object} errors - request failure reasons
 */
/**
 * Async action creator to update user's profile info
 * @param {Object} data - updates to apply
 * @param {successCb} onSuccess - request success handler
 * @param {failureCb} onFailure - request failure handler
 */
const updateProfileInfo = (data, onSuccess, onFailure) => {
  return (dispatch, getState) => {
    const {
      auth: {
        user: { id },
      },
    } = getState();

    const executeRequest = async (token) => {
      return apiPatchUser(token, id, data);
    };

    const handleResponse = ({ status_code, errors, data }) => {
      switch (status_code) {
        case 200:
          dispatch({
            type: PROFILE_INFO_UPDATED,
            payload: data,
          });
          dispatch({
            type: SHOW_NOTIFICATION,
            payload: { variant: "success", message: "profile updated" },
          });
          typeof onSuccess === "function" && onSuccess();
          break;

        case 400:
          typeof onFailure === "function" && onFailure(errors);
          break;

        default:
          break;
      }
    };

    createApiAction(dispatch, getState, executeRequest, handleResponse);
  };
};

export default updateProfileInfo;

import { useDispatch, useSelector } from "react-redux";
import updateProfileInfo from "../../redux/shared/actions/updateProfileInfo";
import changeLanguage from "../../redux/shared/actions/changeLanguage";

import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import { mdiAbjadArabic } from "@mdi/js";

import { LANGS } from "../../constants/languages";

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: "#586285",
    backgroundColor: "#f9fbfe",
    border: "1px solid #edf5fc",
    "&:hover": {
      backgroundColor: "#f9fbfe",
    },
  },
}));

function LanguagesMenu() {
  const classes = useStyles();

  const currentLang = useSelector((state) => state.app.lang);
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const handleLangChange = () => {
    const newLang = currentLang === LANGS.Ara ? LANGS.Eng : LANGS.Ara;

    if (!!user) {
      dispatch(updateProfileInfo({ lang: newLang }));
    } else {
      dispatch(changeLanguage(newLang));
    }
  };

  return (
    <IconButton
      aria-label="toggle language"
      classes={{ root: classes.buttonRoot }}
      onClick={handleLangChange}
    >
      {currentLang === LANGS.Ara ? (
        "E"
      ) : (
        <SvgIcon>
          <path d={mdiAbjadArabic} />
        </SvgIcon>
      )}
    </IconButton>
  );
}

export default LanguagesMenu;

import { useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { create } from "jss";
import rtl from "jss-rtl";
import {
  ThemeProvider,
  StylesProvider,
  jssPreset,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { LANGS_DIR, I18N_MAP } from "../../../constants/languages";

import makeTheme from "../helpers/makeTheme";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function AppTheme({ children }) {
  const { i18n } = useTranslation();

  const lang = useSelector((state) => state.app.lang);
  const color = useSelector((state) => state.shared.settings.color);

  const initLang = useRef(lang);
  useEffect(() => {
    if (lang !== initLang.current) {
      // if language changed reload app TEMP!
      window.location.reload();
    } else {
      document.documentElement.lang = I18N_MAP[lang];
      document.body.dir = LANGS_DIR[lang];
      i18n.changeLanguage(I18N_MAP[lang]);
    }
  }, [lang, i18n]);

  const theme = useMemo(
    () =>
      makeTheme({
        lang: initLang.current,
        dir: LANGS_DIR[initLang.current],
        secondaryColor: color,
      }),
    [color]
  );

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {children}
      </ThemeProvider>
    </StylesProvider>
  );
}

AppTheme.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppTheme;

import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../../utils/localStorage";

import {
  USER_LOGGEDIN,
  PROFILE_INFO_UPDATED,
  CHANGE_LANGUAGE,
} from "../../../../redux/shared/actions/actionTypes";

import { LANGS } from "../../../../constants/languages";

const prefLang = getLocalStorageItem("l");

const INIT_STATE = prefLang || LANGS.Eng;

const langReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_LOGGEDIN:
      // if user has no account type, this isn't the proper dashboard to log into
      if (!payload.user.account_type) return state;

      setLocalStorageItem("l", payload.user.lang);
      return payload.user.lang;

    case PROFILE_INFO_UPDATED:
      setLocalStorageItem("l", payload.lang);
      return payload.lang;

    case CHANGE_LANGUAGE:
      setLocalStorageItem("l", payload);
      return payload;

    default:
      return state;
  }
};

export default langReducer;

import { combineReducers } from "redux";

import configs from "./configs";
import now from "./now";
import filters from "./filters";
import mapBounds from "./mapBounds";
import stats from "./stats";
import data from "./data";
import mapData from "./mapData";

import singleOrder from "./singleOrder";

export default combineReducers({
  configs,
  filters,
  mapBounds,
  stats,
  now,

  data,
  mapData,

  singleOrder,
});

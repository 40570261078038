export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    //TODO: report errors
  }
};

export const getLocalStorageItem = (key) => {
  try {
    const serializedItem = localStorage.getItem(key);
    return serializedItem ? JSON.parse(serializedItem) : undefined;
  } catch (error) {
    //TODO: report errors
    return undefined;
  }
};

export const removeLocalStorageItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    //TODO: report errors
  }
};

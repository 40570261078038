import { useEffect, useRef } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import resetAppReqStatus from "../../redux/shared/actions/resetAppReqStatus";

import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import notFoundSvg from "../../assets/error/404.svg";

const useStyles = makeStyles({
  avatar: { width: 340, height: 340 },
});

function NotFound() {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();
  const { notFound } = useSelector((state) => state.shared.appRequestStatus);

  const { pathname } = useLocation();
  const errPath = useRef(pathname);
  useEffect(() => {
    if (notFound && pathname !== errPath.current) {
      dispatch(resetAppReqStatus());
    }
  }, [dispatch, notFound, pathname]);

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="space-evenly"
      component={Box}
      width="100%"
      height="100%"
    >
      <Grid item>
        <img alt="not found" src={notFoundSvg} className={classes.avatar} />
      </Grid>

      <Grid item component={Box} textAlign="center">
        <Typography component="h1" variant="h4" color="primary" gutterBottom>
          {t("not found")}
        </Typography>

        <Typography color="textSecondary">{t("not found message")}</Typography>

        <Box textAlign="center" mt={2}>
          <Button variant="outlined" color="primary" onClick={history.goBack}>
            {t("go back")}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export default NotFound;

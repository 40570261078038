// icons imported from mdi are SVG string paths
import {
  mdiChartBoxOutline,
  mdiRecordCircleOutline,
  mdiStorefrontOutline,
  mdiTools,
  mdiWalletOutline,
  mdiFileDocumentOutline,
  mdiCogOutline,
  mdiAccountCogOutline,
  mdiAccountGroupOutline,
  mdiBattery10,
  mdiBattery20,
  mdiBattery30,
  mdiBattery40,
  mdiBattery50,
  mdiBattery60,
  mdiBattery70,
  mdiBattery80,
  mdiBattery90,
  mdiBattery,
} from "@mdi/js";

// country SVG assets
import EGP from "../assets/defaults/country/egy.svg";
import SAR from "../assets/defaults/country/sa.svg";
import USD from "../assets/defaults/country/usa.svg";

export const MODULE_ICONS = {
  dashboard: mdiChartBoxOutline,
  orders: mdiRecordCircleOutline,
  branches: mdiStorefrontOutline,
  warehouse: mdiTools,
  team: mdiAccountGroupOutline,
  reports: mdiFileDocumentOutline,
  wallets: mdiWalletOutline,
  settings: mdiCogOutline,
  roles: mdiAccountCogOutline,
};

export const COUNTRY_ICONS = { EGP, SAR, USD };

export const BATTERY_LEVELS = {
  0.1: mdiBattery10,
  0.2: mdiBattery20,
  0.3: mdiBattery30,
  0.4: mdiBattery40,
  0.5: mdiBattery50,
  0.6: mdiBattery60,
  0.7: mdiBattery70,
  0.8: mdiBattery80,
  0.9: mdiBattery90,
  "1.0": mdiBattery,
};

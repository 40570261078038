import HttpClient from "../../utils/HttpClient";

const httpClient = new HttpClient();

/** AUTH SECTION **/
/**
 * Requests Login
 * @param {Object} data - user credentials
 */
export const apiLogin = (data) => {
  return httpClient.post("", "login", data);
};
/**
 * Requests Logout
 * @param {string} token
 */
export const apiLogout = (token) => {
  return httpClient.post(token, "logout");
};
/**
 * Requests logged user's data
 * @param {string} token
 */
export const apiGetLoggedUser = (token) => {
  return httpClient.get(token, "users/info");
};

/** SETTINGS SECTION **/
/**
 * Fetches Settings from the backend
 * @param {string} token - access token
 */
export const apiGetSettings = (token) => {
  return httpClient.get(token, "settings");
};
/**
 * Updates existing settings in the backend
 * @param {string} token - access token
 * @param {Object} data - fields data to patch
 */
export const apiPatchSettings = (token, data) => {
  return httpClient.patch(token, "settings", data);
};

/** COMPANY SECTION **/
/**
 * Updates company in the backend
 * @param {string} token - access token
 * @param {string} id - company id to apply update on
 * @param {Object} data - fields data to patch
 */
export const apiPatchCompany = (token, id, data) => {
  return httpClient.patch(token, `complete-setup/${id}`, data);
};

/** PROFILE SECTION **/
/**
 * Updates existing User in the backend
 * @param {string} token - access token
 * @param {string} id - user id to apply update on
 * @param {Object} data - new updates to add
 */
export const apiPatchUser = (token, id, data) => {
  return httpClient.patch(token, `users/update/${id}`, data);
};
/**
 * Requests a password change
 * @param {Object} data - old and new passwords
 */
export const apiChangePassword = (token, data) => {
  return httpClient.patch(token, "users/update/password", data);
};

/** ORDERS SECTION **/
/**
 * Fetches Orders from the backend
 * @param {string} token - access token
 * @param {Object} queryParams - api search query parameters
 */
export const apiGetOrders = (token, queryParams) => {
  return httpClient.get(token, "orders", queryParams);
};
/**
 * Assigns an order to a captain
 * @param {string} token - access token
 * @param {string} id - order id
 * @param {Object} data - captain data
 */
export const apiAssignOrder = (token, id, data) => {
  return httpClient.post(token, `orders/assign/${id}`, data);
};
/**
 * Dispatches an order
 * @param {string} token - access token
 * @param {string} id - order id
 */
export const apiDispatchOrder = (token, id) => {
  return httpClient.post(token, `orders/dispatch/${id}`);
};

/** USERS SECTION **/
/**
 * Fetches Users from the backend
 * @param {string} token - access token
 * @param {Object} queryParams - api search query parameters
 */
export const apiGetUsers = (token, queryParams) => {
  return httpClient.get(token, "users", queryParams);
};

/** TOOLS SECTION **/
/**
 * Fetches Tool Custodies from the backend
 * @param {string} token - access token
 * @param {Object} queryParams - api search query parameters
 */
export const apiGetToolCustodies = (token, queryParams) => {
  return httpClient.get(token, "warehouse/tools/custody", queryParams);
};
/**
 * Moves Tool Custody from user
 * @param {string} token - access token
 * @param {string} id - tool id
 * @param {Object} data - move fields data
 */
export const apiMoveToolCustody = (token, id, data) => {
  return httpClient.post(token, `warehouse/tools/move/${id}`, data);
};

/** WALLETS SECTION **/
/**
 * Fetches Wallet Custodies from the backend
 * @param {string} token - access token
 * @param {Object} queryParams - api search query parameters
 */
export const apiGetWalletCustodies = (token, queryParams) => {
  return httpClient.get(token, "finance/custody", queryParams);
};
/**
 * Withdraws all Wallet Custodies for a user
 * @param {string} token - access token
 * @param {string} id - user id
 */
export const apiReturnWalletCustodies = (token, id) => {
  return httpClient.post(token, `finance/custody/return/${id}`);
};

/** BRANCHES SECTION **/
/**
 * Fetches Barnches from the backend
 * @param {string} token - access token
 * @param {Object} queryParams - api search query parameters
 */
export const apiGetBranches = (token, queryParams) => {
  return httpClient.get(token, "companies/branches", queryParams);
};

/** ROLES SECTION **/
/**
 * Fetches Roles from the backend
 * @param {string} token - access token
 * @param {Object} queryParams - api search query parameters
 */
export const apiGetRoles = (token, queryParams) => {
  return httpClient.get(token, "roles", queryParams);
};

/** SHIFTS SECTION **/
/**
 * Fetches shifts from the backend
 * @param {string} token - access token
 * @param {Object} queryParams - api search query parameters
 */
export const apiGetShifts = (token, queryParams) => {
  return httpClient.get(token, "settings/shifts", queryParams);
};

/** ATTACHEMENTS SECTION **/
/**
 * Uploads an attachment to the backend - multipart post
 * @param {string} token - access token
 * @param {Object} formData - file to upload in form-data object
 */
export const apiPostAttachment = (token, formData, onUploadProgress) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress,
  };
  return httpClient.post(token, "attachments/create", formData, config);
};

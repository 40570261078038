import { SHIFT_FETCHED } from "../actions/actionTypes";

const singleShiftReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case SHIFT_FETCHED:
      return payload;

    default:
      return state;
  }
};

export default singleShiftReducer;

const ar = {
  qafeer: "قفير",
  logout: "تسجيل خروج",
  profile: "الحساب",

  dashboard: "لوحة التحكم",
  orders: "الطلبات",
  "orders tracking": "تتبع الطلبات",
  "all orders": "كل الطلبات",
  branches: "الفروع",
  warehouse: "المستودع",
  tools: "المعدات",
  shelves: "الرفوف",
  team: "فريق العمل",
  reports: "التقارير",
  wallets: "المحافظ",
  settings: "الإعدادات",
  permissions: "الصلاحيات",
  help: "المساعدة",

  "system settings": "إعدادات النظام",
  "powered by": "مدعوم بواسطة",
  "workers management": "لإدارة العمالة",

  "welcome heading":
    "مرحبًا بك في فاست دليفري، لقد بدأ للتو تجربتك المجانية لمدة ٣٠ يوم!",
  "welcome subheading": "من فضلك أكمل إعدادات شركتك والنظام.",
  "system setup": "إعداد الشركة",
  "number of branches": "عدد الفروع",
  "number of captains": "عدد الكباتن",
  "on trial plan":
    "حسابك الآن على خطة التجربة المجانية لمدة ٣٠ يوم التي تنتهي خلال:",
  "trial ended": "لقد انتهت التجربة المجانية!",
  "plan ended": "لقد انتهت خطتك!",
  "go to subscriptions":
    "يمكنك الذهاب إلى الإشتراكات وشراء الخطة المناسبة للاستمرار باستخدام فاست دليفري",

  save: "حفظ",
  subscriptions: "الإشتراكات",
  "start premium": "للخطط الأساسية",

  "required field": "هذا الحقل أساسي",
  "must be more than one": "يجب أن يكون أكبر من صفر",
};

export default ar;

const ar = {
  "branch added": "تم إضافة الفرع بنجاح",
  "branch updated": "تم تعديل الفرع بنجاح",
  "captain moved": "تم نقل الكابتن بنجاح",
  "captain removed": "تم إخراج الكابتن بنجاح",

  "order added": "تم إضافة الطلب بنجاح",
  "order assigned": "تم تكليف الطلب بنجاح",
  "order dispatched": "تم إرسال الطلب بنجاح",

  "tool added": "تم إضافة المعدة بنجاح",
  "tool updated": "تم تعديل المعدة بنجاح",
  "tool moved": "تم نقل المعدة بنجاح",
  "shelf added": "تم إضافة الرف بنجاح",
  "shelf updated": "تم تعديل الرف بنجاح",

  "custody added": "تم إضافة العهدة بنجاح",

  "report added": "تم إضافة التقرير بنجاح",
  "report sent": "تم إرسال التقرير بنجاح",

  "settings updated": "تم تعديل الإعدادات بنجاح",
  "shift added": "تم إضافة الشيفت بنجاح",
  "shift updated": "تم تعديل الشيفت بنجاح",
  "role added": "تم إضافة الوظيفة بنجاح",
  "role updated": "تم تعديل الوظيفة بنجاح",
  "user role updated": "تم تعديل وظيفة مستخدم بنجاح",

  "user added": "تم إضافة المستخدم بنجاح",
  "user updated": "تم تعديل المستخدم بنجاح",

  "company updated": "تم إعداد الشركة بنجاح",

  "profile updated": "تم تعديل الحساب بنجاح",
  "password changed": "تم تغيير كلمة المرور بنجاح",

  "something went wrong": "حدث خطأ ما، برجاء المحاولة لاحقًا",
  "network error": "حدث خطأ بالإتصال، برجاء المحاولة لاحقًا",
};

export default ar;

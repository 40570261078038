import {
  NOT_FOUND,
  ACCESS_DENIED,
  RESET_REQ_STATUS,
} from "../actions/actionTypes";

const INIT_STATE = {
  notFound: false,
  accessDenied: false,
};

const appRequestStatusReducer = (state = INIT_STATE, action) => {
  const { type } = action;

  switch (type) {
    case NOT_FOUND:
      return { ...state, notFound: true };

    case ACCESS_DENIED:
      return { ...state, accessDenied: true };

    case RESET_REQ_STATUS:
      return INIT_STATE;

    default:
      return state;
  }
};

export default appRequestStatusReducer;

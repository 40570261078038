import {
  ORDERS_PAGE_FETCHED,
  ORDER_ASSIGNED,
  ORDER_DISPATCHED,
} from "../actions/actionTypes";

const INITIAL_STATE = {
  metadata: {},
  data: [],
  filters: {},
  dispatchedOrders: {},
};

const ordersPageReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ORDERS_PAGE_FETCHED:
      return { ...state, ...payload };

    case ORDER_ASSIGNED:
      return {
        ...state,
        data: state.data.map((item) =>
          item.id === payload.id ? { ...item, ...payload } : item
        ),
      };

    case ORDER_DISPATCHED:
      return {
        ...state,
        dispatchedOrders: {
          ...state.dispatchedOrders,
          [payload.orderID]: { dispatchTime: Date.now() },
        },
      };

    default:
      return state;
  }
};

export default ordersPageReducer;

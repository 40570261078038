const ar = {
  role: "الوظيفة",
  roles: "الوظائف",
  user: "المستخدم",
  users: "المستخدمون",
  usersWithCount: "{{count}} مستخدم للوظيفة",
  usersWithCount_plural: "مستخدمون للوظيفة {{count}}",
  web: "الويب",
  mobile: "الموبايل",
  language: "اللغة",
  currency: "العملة",
  supervisor: "مشرف",
  worker: "عامل",
  search: "إبحث عن وظيفة",
  actions: "الخيارات",
  module: "الوحدة",
  shifts: "الشيفتات",
  shift: "الشيفت",
  amount: "الكمية",
  from: "من",
  to: "إلى",
  price: "السعر",
  KM: "كم",
  logo: "الشعار",
  color: "اللون",
  url: "URL",
  secret: "Secret",
  events: "الأحداث",

  "system settings": "إعدادات النظام",
  "general settings": "إعدادات عامة",
  "default wide settings": "إعدادات النظام العامة",
  "order settings": "إعدادات الطلبات",
  "delivery prices for orders": "أسعار توصيل/إرتجاع الطلبات",
  "shift settings": "إعدادات توقيتات العمل",
  "working hours for captains": "ساعات عمل الكباتن",
  "custody settings": "إعدادات العهدة",
  "captains custody settings": "إعدادات عهدة الكباتن",
  "webhooks settings": "إعدادات التزامن",
  "sync with other system": "المزامنة مع نظام آخر",
  "max allowed wallet": "أقصى مبلغ في عهدة المحفظة",
  "leave it empty for no max": "اتركه فارغًا لمبلغ غير محدود",
  "assigned captains": "عدد الكباتن",
  "working hours": "ساعات العمل",
  "off days": "أيام الأجازة",
  "add shift title": "إضافة شيفت جديد",
  "edit shift title": "تعديل شيفت",
  "clone shift title": "إستنساخ شيفت جديد",
  "shift name": "إسم الشيفت",
  "start time": "توقيت البدء",
  "end time": "توقيت الإنتهاء",
  "day off": "اليوم أجازة",
  "delivery/return distance prices": "أسعار مسافات التوصيل/الإرتجاع",
  "apply trip fees on": "تطبيق سعر الرحلة على",
  "delivery order": "رحلات التوصيل",
  "return order": "رحلات الإرتجاع",
  "company logo": "شعار الشركة",
  "order max queue duration": "أقصى مدة إنتظار للطلب",
  "order will be cancelled": "سيتم إلغاء الطلب بعد هذه المدة",

  "roles and permissions": "الوظائف والصلاحيات",
  "role name": "إسم الوظيفة",
  "add role": "إضافة وظيفة جديدة",
  "edit role": "تعديل وظيفة",
  "clone role": "إستنساخ وظيفة جديدة",
  "select all": "إختيار الكل",
  "role details": "تفاصيل الوظيفة",
  "role name ar": "إسم الوظيفة بالعربية",
  "role name en": "إسم الوظيفة بالإنجليزية",
  "system level role": "وظيفة خاصة بالنظام",
  "mobile permissions": "صلاحيات الموبايل",
  "web permissions": "صلاحيات الويب",
  "role users": "مستخدمو الوظيفة",
  "role permissions": "صلاحيات الوظيفة",

  "no access": "لا صلاحيات",
  "no roles": "لا يوجد وظائف إلى الآن",
  "no role users": "لا يوجد مستخدمين للوظيفة إلى الآن",
  "no users without role": "لا يوجد مستخدمين لا يملكون هذه الوظيفة",
  "no shifts": "لا يوجد شيفتات حتى الآن",

  EGP: "جنية مصري",
  USD: "دولار أمريكي",
  SAR: "ريال سعودي",
  EGP_short: "ج.م",
  SAR_short: "ر.س",

  sun: "الأحد",
  mon: "الإثنين",
  tue: "الثلاثاء",
  wed: "الأربعاء",
  thu: "الخميس",
  fri: "الجمعة",
  sat: "السبت",

  add: "أضِف وظيفة",
  edit: "تعديل وظيفة",
  clone: "إستنساخ وظيفة",
  save: "حفظ",
  "save & add": "حفظ وإضافة",
  "b cancel": "رجوع",
  "add user": "أضِف مستخدم",
  "add shift": "أضِف شيفت",
  "edit shift": "تعديل شيفت",
  "clone shift": "إستنساخ شيفت",

  "change user role": "تغيير وظيفة مستخدم",
  "add user to": "أضِف مستخدم إلى",
  "add role to user": "أضِف وظيفة لمستخدم",

  "required field": "هذا الحقل أساسي",
  "too short name": "الإسم قصير جدًا، يجب أن يكون 2 حروف على الأقل",
  "too long name": "الإسم طويل جدًا، يجب أن يكون 50 حرف على الأقل",
  "invalid role": "هذه الوظيفة غير صحيحة",
  "invalid amount": "هذا المبلغ غير صحيح",
  "invalid time": "هذا التوقيت غير صحيح",
  "start should be before end": "البداية يجب ان تكون قبل النهاية",
  "end should be after start": "النهاية يجب أن تكون بعد البداية",
  "fill one day": "يجب ملئ يوم واحد على الأقل",
  "should equal last to": "يجب أن تكون مساوية للنهاية السابقة",
  "should be greater than from": "يجب أن تكون أكبر من البداية",
  "should be greater than last price": "يجب أن يكون أكبر من السعر السابق",
  "59 max minutes": "٥٩ دقيقة كحد أقصى",
  "30 min minutes": "٣٠ دقيقة على الأقل",
  "2 max hours": "ساعتان كحد أقصى",
  "invalid url": "ال URL غير صحيح",
  "20 characters at least": "يجب أن يكون ٢٠ حروف على الأقل",
  "100 characters at most": "يجب أن يكون ١٠٠ حرف على الأكثر",

  dashboard_label: "لوحة القيادة",
  orders_label: "الطلبات",
  branches_label: "الفروع",
  team_label: "فريق العمل",
  warehouse_label: "المستودع",
  tools_label: "المعدات",
  shelves_label: "الرفوف",
  wallets_label: "المحافظ",
  reports_label: "التقارير",
  settings_label: "الإعدادات",
  system_label: "النظام",
  permissions_label: "الصلاحيات",
  "mobile access": "إستخدامات الموبايل",
  dashboard: {
    system_overview: "نظرة عامة عن النظام",
    orders_overview: "إحصائيات الطلبات",
    tools_custody: "عهدة المعدات",
    captains_queue: "متسلسلة الكباتن",
    top_captains: "الكباتن الأعلى أداء",
    top_branches: "الفروع الأعلى أداء",
  },
  orders: {
    view: "رؤية الطلبات",
    statistics: "إحصائيات الطلبات",
    map: "خريطة الطلبات اللحظية",
    timeline: "جدول الطلبات الزمني اللحظي",
    receive_orders: "إستلام الطلبات [يجب أن يكون عامل]",
  },
  branches: {
    view: "رؤية الفروع",
    add: "إضافة فروع جديدة",
    edit: "تعديل الفروع",
    move_users: "نقل المستخدمين بين الفروع",
  },
  team: {
    view: "رؤية المستخدمين",
    add: "إضافة مستخدمين جدد",
    edit: "تعديل المستخدمين",
  },
  warehouse: {
    sections: {
      tools: {
        view: "رؤية المعدات",
        add: "إضافة معدات جديدة",
        edit: "تعديل المعدات",
        move_tool: "نقل المعدات بين المستخدمين",
      },
      shelves: {
        view: "رؤية الرفوف",
        add: "إضافة رفوف جديدة",
        edit: "تعديل الرفوف",
        download_codes: "تحميل باركود الرفوف",
      },
    },
  },
  wallets: {
    view: "رؤية عهدة المحافِظ",
    add: "إضافة عهدة للمستخدمين",
    collect: "تحصيل العهدة من المستخدمين",
  },
  reports: {
    view: "رؤية التقارير",
    add: "إضافة تقارير جديدة",
  },
  settings: {
    sections: {
      system: {
        edit_general_settings: "تعديل الإعدادات العامة",
        edit_orders_settings: "تعديل إعدادات الطلبات",
        edit_custody_settings: "تعديل إعدادات عهدة المحفظة",
        edit_webhooks_settings: "تعديل إعدادات التزامن مع الأنظمة الأخرى",

        view_shifts: "رؤية الشيفتات",
        add_shifts: "إضافة شيفتات جديدة",
        edit_shifts: "تعديل الشيفتات",
      },
      permissions: {
        view: "رؤية الوظائف والصلاحيات",
        add: "إضافة وظائف جديدة",
        edit: "تعديل الوظائف",
        move_users: "نقل المستخدمين بين الوظائف",
      },
    },
  },
};

export default ar;

import PropTypes from "prop-types";

import { useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import logout from "../../../../redux/shared/actions/logout";

import { useTranslation } from "react-i18next";

import clsx from "clsx";
import { makeStyles, alpha } from "@material-ui/core/styles";

import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { mdiChevronDown, mdiAccountCircleOutline, mdiExitRun } from "@mdi/js";

import Link from "../../../../base/components/Link";
import IconMenu from "../../../../base/components/IconMenu";
import IconMenuItem from "../../../../base/components/IconMenuItem";
import IconText from "../../../../base/components/IconText";
import LanguagesMenu from "../../../../base/components/LanguagesMenu";

import { DEFAULT_IMGS } from "../../../../constants/images";

import fastLogo from "../../../../assets/logos/fast_logo_dark.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuButtonActive: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  },
  user: {
    display: "flex",
    alignItems: "center",
    "&>:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  userCard: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 1.5, 0.5, 0.75),
    borderRadius: 100,
  },
  userCardBorder: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  userCardHighlighted: {
    backgroundColor: alpha(theme.palette.blueGrey[50], 0.4),
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  userCardImg: {
    display: "block",
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
}));

function TopBar({ isDrawerOpen, toggleDrawer }) {
  const classes = useStyles();
  const { t } = useTranslation("app");

  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const logo = useSelector((state) => state.shared.settings.logo);

  return (
    <Toolbar className={classes.root}>
      {!!toggleDrawer && (
        <IconButton
          aria-label="toggle drawer"
          onClick={toggleDrawer}
          edge="start"
          color={isDrawerOpen ? "default" : "secondary"}
          className={clsx({ [classes.menuButtonActive]: !isDrawerOpen })}
        >
          <MenuIcon />
        </IconButton>
      )}

      <Box mr="auto" ml={!!toggleDrawer ? "15px" : 0}>
        <Link to="/" underline="none" color="textPrimary">
          {!!logo ? (
            <Box
              component="img"
              alt="logo"
              src={logo}
              maxWidth={160}
              maxHeight={45}
            />
          ) : (
            <Box
              component="img"
              alt="fastdelivery"
              src={fastLogo}
              maxWidth={160}
              maxHeight={45}
            />
          )}
        </Link>
      </Box>

      <div className={classes.user}>
        <IconMenu label="user actions" filled icon={mdiChevronDown}>
          <IconMenuItem to="/profiles/user">
            <IconText
              text={t("profile")}
              variant="body1"
              color="textSecondary"
              icon={mdiAccountCircleOutline}
              iconSize="medium"
              iconColor="action"
            />
          </IconMenuItem>

          <IconMenuItem onClick={() => dispatch(logout())}>
            <IconText
              text={t("logout")}
              variant="body1"
              color="textSecondary"
              icon={mdiExitRun}
              iconSize="medium"
              iconColor="action"
            />
          </IconMenuItem>
        </IconMenu>

        <Link
          to="/profiles/user"
          underline="none"
          color="textPrimary"
          replace={pathname.includes("/profiles/user")}
          className={clsx(classes.userCard, {
            [classes.userCardHighlighted]: pathname.includes("/profiles/user"),
            [classes.userCardBorder]: !pathname.includes("/profiles/user"),
          })}
        >
          <Avatar
            alt="user"
            src={user.image || DEFAULT_IMGS.user}
            variant="circular"
            className={classes.userCardImg}
          />
          <Typography component="span" color="inherit">
            {user.name}
          </Typography>
        </Link>

        <LanguagesMenu />
      </div>
    </Toolbar>
  );
}

TopBar.propTypes = {
  toggleDrawer: PropTypes.func,
  isDrawerOpen: PropTypes.bool.isRequired,
};

export default TopBar;

import { SYSTEM_STATS_FETCHED } from "../actions/actionTypes";

const INIT_STATE = {
  data: {
    delivery_orders: 0,
    return_orders: 0,
    cancelled_orders: 0,
    rejected_orders: 0,
    wallet: 0,
  },
  filters: {},
};

const systemStatsReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SYSTEM_STATS_FETCHED:
      return payload;

    default:
      return state;
  }
};

export default systemStatsReducer;

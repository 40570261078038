const en = {
  "go back": "Go Back",
  "not found": "Not Found",
  "not found message": "We can't find the page you're looking for.",
  "access denied": "Access Denied",
  "access denied message": "You don't have a permission to view this page.",
  "something went wrong": "Something went wrong!",
  "error message":
    "It seems something has been broken, we'll work on it as soon as possible.",
  "no content": "There's no content to show here, yet.",

  browse: "Browse",
  "dnd image": "Drag and Drop Image",
  "dnd file": "Drag and Drop File",
  "dnd files": "Drag and Drop Files",

  ",": ", ",
  "add image": "Add Image",
  "invalid image extension":
    "Image extension should be one of ( {{validExt}} )",
  "invalid image size": "Image should be {{validSize}}MB at max",

  keyword: "Keyword",
  "too long keyword": "Should be 25 letters at most",

  location: "Location",
  "location keywords": "Location keywords",
  "type keywords to search": "Type keywords to search map",
  "cannot find addresses":
    "Can't find addresses, please try different keywords",

  "drop file or browse": "Drop file here or browse to upload",
  "file uploaded successfully": "File uploaded successfully",
};

export default en;

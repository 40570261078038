import {
  MAP_DATA_FETCHED,
  RT_ORDER_ASSIGNED,
  RT_ORDER_UPDATED,
  RT_TRIP_UPDATED,
} from "../actions/actionTypes";

const INIT_STATE = {
  ordersIDs: [],
  ordersTrips: {},
};

const mapDataReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case MAP_DATA_FETCHED:
      return payload.reduce(
        ({ ordersIDs, ordersTrips }, item) => {
          return {
            ordersIDs: [...ordersIDs, item.id],
            ordersTrips: { ...ordersTrips, [item.id]: item.locations },
          };
        },
        {
          ordersIDs: [],
          ordersTrips: {},
        }
      );

    case RT_ORDER_ASSIGNED:
      // add new item for the order
      return {
        ordersIDs: [...state.ordersIDs, payload.id],
        ordersTrips: { ...state.ordersTrips, [payload.id]: [] },
      };

    case RT_ORDER_UPDATED:
      // if order not finished, return state
      if (!payload.returned_at) return state;

      return {
        ...state,
        ordersIDs: state.ordersIDs.filter((id) => id !== payload.id),
      };

    case RT_TRIP_UPDATED:
      const orderID = payload.trip_id;

      return {
        ...state,
        ordersTrips: {
          ...state.ordersTrips,
          [orderID]: [...state.ordersTrips[orderID], payload.coordinates],
        },
      };

    default:
      return state;
  }
};

export default mapDataReducer;

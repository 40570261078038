const en = {
  branches: "Branches",
  branch: "Branch",
  orders: "Orders",
  captains: "Captains",
  captain: "Captain",
  address: "Address",
  id: "Identifier",
  active: "Active",
  actions: "Actions",
  captains_count: "{{count}} Captains",
  orders_count: "{{count}} Orders",

  "search branches": "Search Branches",
  "branch name": "Branch Name",
  "branch image": "Branch Image",
  "branch address": "Branch Address",
  "add branch title": "Add New Branch",
  "clone branch title": "Clone New Branch",
  "edit branch title": "Edit Branch",
  "branch location": "Branch Location",
  "sure to remove?": "Are you sure to remove captain from branch?",
  "choose location and area":
    "Choose branch location and its coverage area on map",

  "no branches": "There's no branches yet",
  "no captains": "There's no captains for branch yet",
  "no other branches": "There's no other branches",

  save: "Save",
  confirm: "Confirm",
  "save & add": "Save & Add",
  "add branch": "Add Branch",
  "edit branch": "Edit Branch",
  "clone branch": "Clone Branch",
  "activate branch": "Activate Branch",
  "deactivate branch": "Deactivate Branch",
  "move to branch": "Move to Branch",
  "remove from branch": "Remove from Branch",

  "required field": "This field is required",
  "too short name": "Too short name, should be 2 letters at least",
  "too long name": "Too long name, should be 50 letters at most",
  "too short address": "Too short address, should be 10 letters at least",
  "too long address": "Too long address, should be 100 letters at most",
  "invalid branch": "Invalid branch",
  "position required": "Position required",
  "area required": "Branch coverage area required",
  "invalid area": "Invalid coverage area",
  "location outside area": "Location should be inside branch coverage area",
};

export default en;

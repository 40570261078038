import branch from "../assets/defaults/branch.png";
import tool from "../assets/defaults/tool.png";
import shelf from "../assets/defaults/shelf.png";
import user from "../assets/defaults/user.png";
import report from "../assets/defaults/report.png";
import money from "../assets/defaults/money.png";
import order from "../assets/defaults/order.png";
import orderDelivery from "../assets/defaults/order_delivery.png";
import orderReturn from "../assets/defaults/order_return.png";

import store from "../assets/onboarding/store.svg";
import darkStore from "../assets/onboarding/dark_store.svg";
import restaurant from "../assets/onboarding/restaurant.svg";
import cloudKitchen from "../assets/onboarding/cloud_kitchen.svg";

export const DEFAULT_IMGS = {
  branch,
  tool,
  shelf,
  user,
  report,
  money,
  order,
  orderDelivery,
  orderReturn,
};

export const BUSINESS_IMAGES = { store, darkStore, restaurant, cloudKitchen };

export const SHIFTS_FETCHED = "SET_SHIFTS_FETCHED";
export const SHIFT_FETCHED = "SET_SHIFT_FETCHED";
export const SHIFT_ADDED = "SET_SHIFT_ADDED";
export const SHIFT_UPDATED = "SET_SHIFT_UPDATED";

export const ROLES_PAGE_FETCHED = "SET_ROLES_PAGE_FETCHED";
export const ROLE_FETCHED = "SET_ROLE_FETCHED";
export const ROLE_ADDED = "SET_ROLE_ADDED";
export const ROLE_UPDATED = "SET_ROLE_UPDATED";
export const DEFAULT_ROLE_FETCHED = "SET_DEFAULT_ROLE_FETCHED";

export const ALL_ROLE_USERS_FETCHED = "SET_ALL_ROLE_USERS_FETCHED";
export const ALL_USERS_WITHOUT_ROLE_FETCHED =
  "SET_ALL_USERS_WITHOUT_ROLE_FETCHED";

export const USER_ROLE_UPDATED = "SET_USER_ROLE_UPDATED";

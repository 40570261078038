const ar = {
  warehouse: "المستودع",
  tools: "المعدات",
  tool: "المعدة",
  custody: "العهدة",
  history: "التحركات",
  worker: "العامل",
  user: "المستخدم",
  actions: "الخيارات",
  brand: "الماركة",
  date: "التاريخ",
  shelves: "الرفوف",
  shelf: "الرف",
  branch: "الفرع",

  "search tools": "إبحث عن معدة",
  "search shelves": "إبحث عن رف",
  "add tool": "إضافة معدة جديد",
  "edit tool": "تعديل معدة",
  "clone tool": "إستنساخ معدة جديد",
  "add shelf": "إضافة رف جديد",
  "edit shelf": "تعديل رف",
  "clone shelf": "إستنساخ رف جديد",
  "operation for": "الحركة إلى",
  "created by": "بواسطة",
  "tool name ar": "إسم المعدة بالعربية",
  "tool name en": "إسم المعدة بالإنجليزية",
  "tool image": "صورة المعدة",
  "shelf name ar": "إسم الرف بالعربية",
  "shelf name en": "إسم الرف بالإنجليزية",
  "shelf image": "صورة الرف",
  "shelf number": "رقم الرف",
  "shelf code": "كود الرف",
  "download barcode": "تحميل الباركود",
  "serial number": "الرقم التسلسلي",
  "model number": "رقم الموديل",
  "tool transfer": "نقل معدة",
  "to worker": "إلى عامل",
  "to shelf": "إلى رف",
  "tools custodies": "عهدة المعدات",
  "pos machine": "ماكينة دفع",
  "download barcodes pack": "تحميل حزمة باركود",
  "please select branch": "برجاء إختيار الفرع لتحميل حزمة الباركود الخاصة به.",

  "nothing to show": "لا توجد معلومات للعرض",
  "no tools": "لا يوجد معدات حتى الآن",
  "no shelves": "لا يوجد رفوف حتى الآن",
  "no branches": "لا يوجد فروع حتى الآن",
  "no captains": "لا يوجد كباتن حتى الآن",
  "no custody for worker": "لا يوجد عهدة حتى الآن لدى {{workerName}}",

  save: "حفظ",
  cancel: "رجوع",
  "save & add": "حفظ وإضافة",
  "b add tool": "أضِف معدة",
  "b edit tool": "تعديل المعدة",
  "b clone tool": "إستنسخ المعدة",
  "move tool": "نقل المعدة",
  "b add shelf": "أضِف رف",
  "b edit shelf": "تعديل الرف",
  "b clone shelf": "إستنسخ الرف",
  "download pack": "تحميل حزمة",
  download: "تحميل",

  "required field": "هذا الحقل أساسي",
  "too short name": "الإسم قصير جدًا، يجب أن يكون حرفين على الأقل",
  "too long name": "الإسم طويل جدًا، يجب أن يكون ٥٠ حرف على الأكثر",
  "invalid branch": "هذا الفرع غير صحيح",
  "invalid shelf": "هذا الرف غير صحيح",
  "invalid captain": "هذا الكابتن غير صحيح",
  "branch has no shelves": "لا يوجد رفوف بهذا الفرع",
};

export default ar;

const ar = {
  dashboard: "لوحة التحكم",
  from: "من",
  to: "إلى",
  captain: "الكابتن",
  branch: "الفرع",
  ranking: "التصنيف",
  orders: "الطلبات",
  distance: "المسافة",
  income: "الدخل",
  wallets: "المحافظ",
  wallet: "المحفظة",
  status: "الحالة",
  tool: "المعدة",
  EGP: "ج.م",
  SAR: "ر.س",
  KM: "كم",
  km_amount: "{{amount}}كم",

  "system overview": "نظرة عامة عن النظام",
  "captains queue": "متسلسة الكباتن",
  "delivery orders": "طلبات التوصيل",
  "return orders": "طلبات الإرتجاع",
  "cancelled orders": "الطلبات الملغاه",
  "rejected orders": "الطلبات المرفوضة",
  "last updated at": "آخر تحديث",
  "top performing captains": "الكباتن الأعلى أداء",
  "top performing branches": "الفروع الأعلى أداء",
  "change dates for new data": "غير التواريخ لبيانات جديدة",
  "last activity": "آخر فعالية",
  "orders statistics": "إحصائيات الطلبات",
  "for 8 days": "لمدة ٨ أيام",
  "tools custody": "عهدة المعدات",

  "nothing to show": "لا يوجد شيء للعرض حاليًا",
  "no branches": "لا يوجد فروع",

  "invalid date": "تاريخ غير صحيح",
  "should be before end": "يجب أن يكون قبل النهاية",
  "should be after start": "يجب أن يكون بعد البداية",

  update: "تحديث",

  ACTIVE: "نشط",
  IDLE: "متفرغ",
  OFF_SHIFT: "خارج الشيفت",
  VACATION: "اجازة",
  BREAK: "إستراحة",
  MAX_WALLET: "المحفظة ممتلئة",
};

export default ar;
